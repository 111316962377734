import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BreadcrumbGoBack } from "../../components/breadcrumb/Breadcrumb";
import Button from "../../components/utilities/button";
import SuccessAnimation from "../../components/animations/SuccessAnimation";

function ChangePassword() {
  const { t } = useTranslation();
  const { email } = useParams();
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [formData, setFormData] = useState({
    token: "",
    password: "",
    password_confirm: "",
  });

  const { token, password, password_confirm } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (password !== password_confirm) {
      setResMsg("Password tidak sama!");
    } else {
      await fetchAPI();
    }
  };

  const fetchAPI = async () => {
    const urlDev = process.env.REACT_APP_API_URL_DEV;
    const urlProd = process.env.REACT_APP_API_URL_PROD;

    const API_URL =
      process.env.REACT_APP_ENV === "development" ? urlDev : urlProd;

    try {
      const response = await axios.post(`${API_URL}auth/change-password`, {
        email,
        token,
        password,
        password_confirm,
      });

      if (!response.data.success) {
        setIsSuccess(false);
        setResMsg(response.data.msg);
      } else {
        setIsSuccess(true);
        setResMsg(response.data.msg);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      setResMsg("An error occurred. Please try again.");
    }
  };

  const resetMsg = () => {
    setResMsg("");
  };

  return (
    <>
      <div className="absolute top-0 left-0 w-full">
        <BreadcrumbGoBack title={"Change Password"} />
      </div>
      {isSuccess ? (
        <div className="w-full h-screen flex flex-col justify-center items-center gap-4">
          <SuccessAnimation />
          <h2 className="text-lg max-w-[300px] text-center">{resMsg}</h2>
        </div>
      ) : (
        <div className="flex flex-col justify-center md:items-center md:w-2/4 xl:w-2/6 mx-auto h-screen">
          <div className="flex flex-col items-center px-8 mb-3">
            <h1 className="font-medium text-2xl md:text-3xl mb-2">
              {t('not_login_title')}{" "}
              <span className="font-semibold text-[#FCCF08]">Xaurius</span>
            </h1>
            <p className="text-center text-[#667085]">
              {t('change_password_desc')}
            </p>
          </div>
          <form onSubmit={submitForm} className="w-full px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="token"
              >
                Token
              </label>
              <input
                id="token"
                name="token"
                type="text"
                placeholder={t('enter_token')}
                onChange={onChange}
                value={token}
                onClick={resetMsg}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="new-password"
              >
                {t('new_password')}
              </label>
              <input
                id="new-password"
                name="password"
                type="password"
                onChange={onChange}
                value={password}
                onClick={resetMsg}
                placeholder={t('enter_password')}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="Confirmpassword"
              >
                {t('confirm_password')}
              </label>
              <input
                id="Confirmpassword"
                name="password_confirm"
                type="password"
                placeholder={t('enter_password')}
                onChange={onChange}
                value={password_confirm}
                onClick={resetMsg}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              />
              {resMsg && (
                <span className="text-sm text-pink-700">{resMsg}</span>
              )}
            </div>

            <div className="flex flex-col items-center justify-between">
              <Button width={"full"} type={"submit"}>
                {t('change_password')}
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default ChangePassword;
