import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import FormBankUser from "./forms/FormBankUser";
import { createBankUser, getBankUser, updateBankUser } from "../features/banks/bankUser/bankUserSlice";
import Modal from "./utilities/modal";

function BankAccount() {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { bankUser, isSuccess } = useSelector((state) => state.bankUser);

  const [showModalAddBank, setShowModalAddBank] = useState(false); // toggle add bank

  const [formData, setFormData] = useState({
    nama_akun: "",
    nama_bank: "",
    nomor_rekening: "",
  });

  const { nama_akun, nama_bank, nomor_rekening } = formData;

  const [messageBankName, setMessageBankName] = useState();
  const [messageAccountNumber, setMessageAccountNumber] = useState();
  const [messageTotalAmount, setMessageTotalAmount] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) {
      // navigate("/deposit-idr");
    }

    // dispatch(reset());
    dispatch(getBankUser());
  }, [user, isSuccess, dispatch]);

  const onChange = (e) => {
    console.log(e.target.value);
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    // cek pemilihan bank
    if (e.target.value !== "") {
      setMessageBankName("");
    }
  };

  const onSubmitAddBank = (e) => {
    e.preventDefault();

    dispatch(createBankUser(formData));

    console.log(formData);
    // cek jika user typing . di jumlah withdraw
    // if (bankname === "" && totalamount === "") {
    //   setMessageBankName("Please select bank name");
    //   setMessageAccountNumber("Input your account bank number");
    //   setMessageTotalAmount("Fill the amount");
    // } else if (bankname === "") {
    //   setMessageBankName("Bank name couldn't be empty");
    // } else if (accountnumber === "") {
    //   setMessageAccountNumber("Account number couldn't be empty");
    // } else if (totalamount === "") {
    //   setMessageTotalAmount("Fill the amount");
    // } else {
    //   setMessageBankName("");
    //   setMessageAccountNumber("");
    //   setMessageTotalAmount("");
    //   console.log(formData);
    //   // navigate("/transfer-bank", { state: formData });
    // }
  };

  const onSubmitUpdateBank = (e) => {
    e.preventDefault();

    dispatch(updateBankUser(formData));
  };

  const onClickShow = (e) => {
    e.preventDefault();
    // setShowAddBank(!showAddBank);
    setShowModalAddBank(!showModalAddBank);
  };

  const onClickClose = (e) => {
    setShowModalAddBank(!showModalAddBank);
  };

  const modal = (
    <Modal onClose={onClickClose}>
      <FormBankUser />
    </Modal>
  );

  return (
    <>
      <div className="bg-white radius-lg">
        <div className="px-8">
          <div className="py-8 text-center text-xs md:text-base">
            <h2>{t('bank_account_title')}</h2>
            <p>{t('bank_account_desc')}.</p>
          </div>
          <button className="w-full bg-[#FCCF08] hover:bg-blue-70 text-black font-bold py-2 px-4 mb-3 rounded-[34px] focus:outline-none focus:shadow-outline" type="submit" onClick={onClickShow}>
          {t('bank_account_desc')}
          </button>
        </div>
      </div>

      {/* popup add bank */}
      {showModalAddBank && modal}
    </>
  );
}

export default BankAccount;
