import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { getCountries } from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
// import { createKYC } from "../../features/kyc/kycSlice";
import createKYC from "../../features/kyc/kycService";
import Modal from "react-modal";
import WarningImg from "../../assets/images/warning-icon.png"
import SuccessAnimation from "../animations/SuccessAnimation";

import "react-phone-number-input/style.css";

// modal custom
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function FormKYC1() {
  // CAUTION!
  // di sini kita memisahkan form select negara karena menggunakan library beda sendiri dari form lainnya.
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.kyc
  );

  const [resSuccess, setResSuccess] = useState(false)
  const [resMsg, setResMsg] = useState('')

  const labelCountry = en;
  const [formDataCountry, setFormDataCountry] = useState();
  const [formDataPhoneNumber, setFormDataPhoneNumber] = useState();
  const [imageKtp, setImageKtp] = useState(null);
  const [imageNpwp, setImageNpwp] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingNpwp, setIsDraggingNpwp] = useState(false);

  const [formData, setFormData] = useState({
    full_name: "",
    address: "",
    id_number: "",
    npwp_number: "",
    file_npwp: null,
    file_ktp: null,
  });

  const [errors, setErrors] = useState({
    full_name: "",
    address: "",
    id_number: "",
    phone_number: ""
  });

  // gabungkan data negara dan lainnya
  const step1Data = {
    ...formData,
    country: formDataCountry ? formDataCountry : "",
    phone_number: formDataPhoneNumber ? formDataPhoneNumber : "",
  };

  // const [messageKYCStep1, setMessageKYCStep1] = useState(message);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      // console.log(message);
      // setMessageAmountXAU(message);
    }

    if (isSuccess) {
      navigate("/status-kyc", {
        state: {
          step1Data,
          status: "pending",
        },
      });
    }
  }, [user, isError, isSuccess, message, dispatch, navigate, step1Data]);

  if (isLoading) {
    return <Loading />;
  }

  // image ktp uploader
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleImage(file);
    }
  };

  const handleImage = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageKtp(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    setFormData(prevState => ({
      ...prevState,
      file_ktp: file
    }));
    handleImage(file);
  };

  // image npwp uploader
  const handleDragEnterNpwp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingNpwp(true);
  };

  const handleDragLeaveNpwp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingNpwp(false);
  };

  const handleDropNpwp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingNpwp(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleImageNpwp(file);
    }
  };

  const handleImageNpwp = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageNpwp(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChangeNpwp = (e) => {
    const file = e.target.files[0];
    setFormData(prevState => ({
      ...prevState,
      file_npwp: file
    }));
    handleImageNpwp(file);
  };

  const isFormValid = () => {
    let valid = true;
    const newErrors = { ...errors };

    for (const key in step1Data) {
      if (key !== 'file_npwp' && key !== 'npwp_number') {
        if (step1Data[key] === "" || step1Data[key] == null) {
          newErrors[key] = `${key} tidak boleh kosong`;
          valid = false;
        } else {
          newErrors[key] = "";
        }
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const onChange = (key, e) => {
    setFormData({ ...formData, [key]: e });

    if (e !== "") {
      setErrors({ ...errors, [key]: "" });
    }
  };

  const onChangeCountry = (e) => {
    setFormDataCountry(e.target.value);
  };

  const onChangePhoneNumber = (e, b, c) => {
    setFormDataPhoneNumber(e);
    console.log(e, b, c);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    console.table(step1Data);

    if (isFormValid()) {
      createKYC(step1Data)
      .then(result => {
        setResSuccess(result.success)

        if (result.success) {
          setResMsg(result.msg)
          setTimeout(() => {
            closeModal()
            navigate('/dashboard')
          }, 2000)
        } else {
          setResMsg(parseErrorMessages(result.msg))
          setTimeout(() => {
            closeModal()
          }, 2000)
        }
        openModal()
        console.log('KYC berhasil dibuat:', result);
      })
      .catch(error => {
        console.error('Gagal membuat KYC:', error.message);
      });
    } else {
      console.log("Formulir tidak valid, pastikan semua field diisi");
    }
  };

  // Fungsi untuk mengurai pesan kesalahan JSON
  const parseErrorMessages = (rawErrorMessage) => {
    try {
      const errorMessageObject = JSON.parse(rawErrorMessage);
      // Menggabungkan atribut dan pesan kesalahan
      const formattedErrorMessages = Object.entries(errorMessageObject)
        .map(([attribute, messages]) => `${attribute} ${messages.join(', ')}`)
        .join(', ');
      return formattedErrorMessages;
    } catch (parseError) {
      console.error('Gagal mengurai pesan kesalahan JSON:', parseError.message);
      return 'Terjadi kesalahan saat mengurai pesan kesalahan.';
    }
  };


  // handle modal
  let subtitle;

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="px-6 pb-32">
        <div className="md:px-8">
          <form onSubmit={onSubmit}>
            <h1 className="font-bold text-xl text-center mt-8 mb-4">
              {t('personal_information')}
            </h1>
            {/* full name */}
            <div className="py-2 flex flex-col border-b border-gray-100">
              <h3 className="font-semibold">
              {t('full_name')} <span className="text-red-500">*</span>
              </h3>
              <span className="text-base text-gray-400 mb-2">
              {t('full_name_desc')}
              </span>
              <input
                id="kycFullName"
                name="full_name"
                type="text"
                value={formData.full_name}
                placeholder={t('full_name')}
                onChange={(e) => onChange("full_name", e.target.value)}
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline valid:[&:not(:placeholder-shown)]:border-green-500 [&:not(:placeholder-shown):not(:focus):invalid~span]:block invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-400"
              />
              {errors.full_name && (
                <span className="text-red-500 text-sm">{errors.full_name}</span>
              )}
            </div>
            {/* end full name */}
            {/* end citizenship */}
            <div className="py-2 flex flex-col border-b border-gray-100">
              <h3 className="font-semibold">
              {t('citizenship')} <span className="text-red-500">*</span>
              </h3>
              <span className="text-base text-gray-400 mb-2">
              {t('citizenship_desc')} {formDataCountry}
              </span>

              <select value={formDataCountry} onChange={onChangeCountry}>
                <option value="">{labelCountry["ZZ"]}</option>
                {getCountries().map((negara) => {
                  // console.log(labelCountry[negara]);

                  return (
                    <option key={negara} value={labelCountry[negara]}>
                      {labelCountry[negara]}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* end citizenship */}
            {/* phone number */}
            <div className="py-2 flex flex-col border-b border-gray-100">
              <h3 className="font-semibold">
              {t('phone')} <span className="text-red-500">*</span>
              </h3>
              <span className="text-base text-gray-400 mb-2">
              {t('phone_desc')}
              </span>
              <PhoneInput
                placeholder={t('enter_phone')}
                defaultCountry="ID"
                value={formDataPhoneNumber}
                onChange={onChangePhoneNumber}
              />
              {errors.phone_number && (
                <span className="text-red-500 text-sm">{errors.phone_number}</span>
              )}
            </div>
            {/* end phone number */}
            {/* address */}
            <div className="py-2 flex flex-col border-b border-gray-100">
              <h3 className="font-semibold">
              {t('address')} <span className="text-red-500">*</span>
              </h3>
              <span className="text-base text-gray-400 mb-2">
              {t('address_desc')}
              </span>
              <textarea
                id="kycAddress"
                name="address"
                type="text"
                value={formData.address}
                placeholder={t('place_text')}
                onChange={(e) => onChange("address", e.target.value)}
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {errors.address && (
                <span className="text-red-500 text-sm">{errors.address}</span>
              )}
            </div>
            {/* end address */}

            {/* identity */}
            <div className="py-2 flex flex-col border-b border-gray-100">
              <h3 className="font-semibold">
              {t('id_number')} <span className="text-red-500">*</span>
              </h3>
              <span className="text-base text-gray-400 mb-2">
              {t('id_number_desc')}
              </span>
              <input
                id="kycIDNumber"
                name="id_number"
                type="text"
                value={formData.id_number}
                placeholder="367098577439"
                onChange={(e) => onChange("id_number", e.target.value)}
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {errors.id_number && (
                <span className="text-red-500 text-sm">{errors.id_number}</span>
              )}
            </div>
            {/* end identity */}
            {/* upload identity */}
            <div className="py-2 flex flex-col border-b border-gray-100">
              <h3 className="font-semibold">{t('upload_id_card')}</h3>
              <div
                className="h-64 mt-3 border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center relative"
                onDragEnter={handleDragEnter}
                onDragOver={(e) => e.preventDefault()}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                {imageKtp ? (
                  <img
                    src={imageKtp}
                    alt="Uploaded"
                    className="h-full w-full object-cover rounded-lg"
                  />
                ) : (
                  <label
                    htmlFor="upload"
                    className={`flex flex-col items-center justify-center space-y-2 ${
                      isDragging ? "border-blue-500" : "border-gray-400"
                    }`}
                  >
                    <img src="/images/featured-icon.png" alt="upload img" />
                    <span className="text-lg text-[#FCCF08] font-bold cursor-pointer">
                      Click to upload <span className="text-[#1F1746] font-normal">or drag and drop</span>
                    </span>
                    <p className="text-sm text-[#626435]">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                  </label>
                )}
                <input
                  type="file"
                  id="upload"
                  accept="image/*"
                  className="hidden"
                  onChange={handleInputChange}
                />
              </div>
              {/* <input
                id="kycUploadIDNumber"
                name="file_ktp"
                type="file"
                value={formData.file_ktp}
                placeholder="Drop your file"
                onChange={(e) => onChange("file_ktp", e.target.value)}
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              /> */}
              {errors.file_ktp && (
                <span className="text-red-500 text-sm">{errors.file_ktp}</span>
              )}
            </div>
            {/* end upload identity */}
            {/* tax id */}
            <div className="py-2 flex flex-col border-b border-gray-100">
              <h3 className="font-semibold">{t('npwp_number')}</h3>
              <span className="text-base text-gray-400 mb-2">
                {t('npwp_number_desc')}
              </span>
              <input
                id="kycTaxID"
                name="npwp_number"
                type="text"
                value={formData.npwp_number}
                placeholder="367098577439"
                onChange={(e) => onChange("npwp_number", e.target.value)}
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            {/* end tax id */}
            {/* upload tax id */}
            <div className="py-2 flex flex-col border-b border-gray-100">
              <h3 className="font-semibold">{t('upload_npwp')}</h3>
              <div
                className="h-64 mt-3 border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center relative"
                onDragEnter={handleDragEnterNpwp}
                onDragOver={(e) => e.preventDefault()}
                onDragLeave={handleDragLeaveNpwp}
                onDrop={handleDropNpwp}
              >
                {imageNpwp ? (
                  <img
                    src={imageNpwp}
                    alt="Uploaded"
                    className="h-full w-full object-cover rounded-lg"
                  />
                ) : (
                  <label
                    htmlFor="uploadNpwp"
                    className={`flex flex-col items-center justify-center space-y-2 ${
                      isDraggingNpwp ? "border-blue-500" : "border-gray-400"
                    }`}
                  >
                    <img src="/images/featured-icon.png" alt="upload img" />
                    <span className="text-lg text-[#FCCF08] font-bold cursor-pointer">
                      Click to upload <span className="text-[#1F1746] font-normal">or drag and drop</span>
                    </span>
                    <p className="text-sm text-[#626435]">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                  </label>
                )}
                <input
                  type="file"
                  id="uploadNpwp"
                  accept="image/*"
                  className="hidden"
                  onChange={handleInputChangeNpwp}
                />
              </div>
              {/* <input
                id="kycUploadTaxID"
                name="file_npwp"
                type="file"
                value={formData.file_npwp}
                placeholder="Drop your file"
                onChange={(e) => onChange("file_npwp", e.target.value)}
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              /> */}
            </div>
            {/* end upload tax id */}
            <div className="py-2 flex flex-row-reverse">
              {/* <div>x</div> */}
              {/* <Button type={"submit"}> */}
              {/* <Link to={""}> */}
              {/* Kirim */}
              {/* </Link> */}
              {/* </Button> */}
              <button
                className="inline-block bg-[#FCCF08] hover:bg-blue-70 text-black font-bold py-2 px-8 rounded-[34px] focus:outline-none focus:shadow-outline"
                type="submit"
              >
                <span className="text-slate-600">{t('send')}</span>
              </button>
            </div>
          </form>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="flex flex-col items-center">
            {
              resSuccess ? <SuccessAnimation /> :
            <img src={WarningImg} alt="warning" width="100"/>
            }
            <span className="my-4" ref={(_subtitle) => (subtitle = _subtitle)}>
              <div dangerouslySetInnerHTML={{__html: resMsg}} className="text-[#344054] font-bold max-w-md text-center">

              </div>
            </span>
            <div className="w-full flex justify-end mt-3">
              <button
                onClick={closeModal}
                className="bg-btn-gold rounded-lg text-sm w-16 h-7 hover:bg-gold2"
              >
                {t('close')}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default FormKYC1;
