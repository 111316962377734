import {React, useEffect} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from 'react-i18next';
import "react-toastify/dist/ReactToastify.css";
import Homepage from "./pages/client/Homepage";
import FAQ from "./pages/client/FAQ";
import TermsOfService from "./pages/client/TermsOfService";
import Blog from "./pages/client/Blog";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import VerifyEmail from "./pages/auth/VerifyEmail";
import ChangePassword from "./pages/auth/ChangePassword";
import AuthSuccess from "./pages/auth/AuthSuccess";
import KYC from "./pages/kyc";
import StatusKYC from "./pages/status/StatusKYC";
import Profile from "./pages/profile";
import ProfileUpdate from "./pages/profile/profileUpdate";
import DepositIDR from "./pages/deposit/DepositIDR";
import DepositXAU from "./pages/deposit/DepositXAU";
import TutorialTransfer from "./pages/TutorialTransfer";
import TransferXAU from "./pages/transfer/TransferXAU";
import WithdrawIDR from "./pages/withdraw/WithdrawIDR";
import WithdrawXAU from "./pages/withdraw/WithdrawXAU";
import History from "./pages/history";
import BuyXAU from "./pages/BuyXAU";
import StatusBuyXAU from "./pages/status/StatusBuyXAU";
import SellXAU from "./pages/SellXAU";
import StatusSellXAU from "./pages/status/StatusSellXAU";
import StatusWithdrawIDR from "./pages/status/StatusWithdrawIDR";
import StatusWithdrawXAU from "./pages/status/StatusWithdrawXAU";
import "./App.css";
import DepositDetail from "./pages/deposit/DepositDetail";
import DepositUpload from "./pages/deposit/DepositUpload";
import PageNotFound from "./pages/notfound";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/email-verification/:email" element={<VerifyEmail />} />
          <Route path="/change-password/:email" element={<ChangePassword />} />
          <Route path="/auth-success/:email" element={<AuthSuccess />} />
          <Route path="/kyc" element={<Navigate to="/kyc-step-1" replace />} />
          <Route path="/kyc-step-1" element={<KYC />} />
          <Route path="/status-kyc" element={<StatusKYC />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile-edit" element={<ProfileUpdate />} />
          <Route path="deposit">
            <Route path="idr" element={<DepositIDR />} />
            <Route path="xau" element={<DepositXAU />} />
          </Route>
          <Route path="/transfer-bank" element={<TutorialTransfer />} />
          <Route path="/transfer-xau" element={<TransferXAU />} />
          <Route path="withdraw">
            <Route path="idr" element={<WithdrawIDR />} />
            <Route path="xau" element={<WithdrawXAU />} />
          </Route>
          <Route path="/history" element={<History />} />
          <Route path="/history/:id" element={<DepositDetail />} />
          <Route path="/history/upload/:id" element={<DepositUpload />} />
          <Route path="/status-withdraw-idr" element={<StatusWithdrawIDR />} />
          <Route path="/status-withdraw-xau" element={<StatusWithdrawXAU />} />
          <Route path="/buy-xau" element={<BuyXAU />} />
          <Route path="/status-buy-xau" element={<StatusBuyXAU />} />
          <Route path="/sell-xau" element={<SellXAU />} />
          <Route path="/status-sell-xau" element={<StatusSellXAU />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
