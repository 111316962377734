import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Nav from "../../components/Nav";
import NotLogin from "../../components/auth/NotLogin";
import { BreadcrumbGoBack } from "../../components/breadcrumb/Breadcrumb";
import FormDepositXAU from "../../components/forms/FormDepositXAU";
import FormWithdrawXAU from "../../components/forms/FormWithdrawXAU";
import { getProfile } from "../../features/profile/profileSlice";
import Review from "../../assets/images/review.gif"

import "swiper/css";
import RecentHistory from "../history/Recenthistory";

function TransferXAU() {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const { message } = useSelector((state) => state.balanceIDR);
  const { bankXAU, isError } = useSelector((state) => state.bankXAU);

  const [showTab, setShowTab] = useState(0); // toggle tabs

  const messageStatusKYC = message;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile()); // check kyc status
    // console.log(profile.data.status_kyc);
  }, [user, bankXAU, isError, dispatch]);

  const onClickShow = (val) => {
    setShowTab(val);
  };

  return (
    <>
      {user && user.success ? (
        <>
          {profile.data && profile.data.status_kyc === "approved" ? (
            <>
              <Nav />
              <div className="md:pl-[30vw] xl:pl-[20vw]">
                <BreadcrumbGoBack title={"Crypto"} />
                <div className="">
                  <div className="flex flex-auto items-center">
                    <div onClick={() => onClickShow(0)} id={0} className={`w-full flex items-center justify-center text-center h-10 bg-slate-200 cursor-pointer`}>
                      <div className={`${showTab === 0 && 'bg-yellow-color w-full mx-2 p-1 rounded-lg '}`}>
                      {t('xau_deposit')}
                      </div>
                    </div>
                    <div onClick={() => onClickShow(1)} id={1} className={`w-full flex items-center justify-center text-center h-10  py-2 bg-slate-200 cursor-pointer`}>
                    <div className={`${showTab !== 0 && 'bg-yellow-color w-full mx-2 p-1 rounded-lg'}`}>
                      {t('Penarikan XAU')}
                      </div>
                    </div>
                  </div>
                  {showTab === 0 ? <FormDepositXAU /> : <FormWithdrawXAU />}
                  <RecentHistory histories={"withdraw_cryptos"} />
                </div>
              </div>
            </>
          ) : (
            <>
              <BreadcrumbGoBack />
              <div className="flex flex-col justify-center md:items-center md:w-2/4 xl:w-2/6 mx-auto h-screen">
                <div className="flex flex-col items-center px-8 mb-3">
                  <img src={Review} alt="" />
                  <p>{messageStatusKYC}.</p>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <NotLogin />
        </>
      )}
    </>
  );
}

export default TransferXAU;
