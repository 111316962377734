import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiChevronLeft } from "react-icons/hi2";

export const PendingBuyXAU = ({ status }) => {
  // console.log(status);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col items-center p-4 mb-4">
        <h1 className="text-xl font-semibold ml-4">{t('pending_buy_title')}</h1>
        <span>{t('pending_buy_desc')}</span>
        <Link to={"/history"}>
          <span className="font-semibold">{t('pending_buy_link')}</span>
        </Link>
      </div>
    </>
  );
};
