import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";
import HistoryTransaction from "./history/HistoryTransaction";
import { getHistory } from "../features/history/historySlice";

function RecentTransactions() {
  const { t } = useTranslation();
  const { history, isLoading } = useSelector((state) => state.history);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHistory());
  }, [dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="w-full mt-6 p-3 shadow-md rounded-xl">
        <h2 className="mb-3 text-[#486284]">{t('recent_transaction')}</h2>
        <div className="w-full">
          <div className="w-full">
            {history.data &&
              history.data.map((list) => {
                let judul;
                let total;
                let ammountIDR;
                let ammountXAU;
                let jumlahIDR;
                let jumlahXAU;
                let txhash;
                if (list.table === "deposits") {
                  judul = "Setoran IDR";
                  total = list.obj.total;
                  ammountIDR = "Jumlah";
                } else if (list.table === "withdraws") {
                  judul = "Penarikan IDR";
                  total = list.obj.ammount;
                  ammountIDR = "Jumlah";
                } else if (list.table === "withdraw_cryptos") {
                  judul = "Penarikan XAU";
                  total = list.obj.amount;
                  txhash = list.obj.txhash;
                  ammountIDR = "Jumlah";
                } else if (list.table === "buys") {
                  judul = "Pembelian XAU";
                  total = list.obj.price;
                  jumlahIDR = list.obj.amount_idr;
                  const num = list.obj.amount_xau;
                  const fix = parseFloat(num).toFixed(1);
                  jumlahXAU = fix;
                  ammountIDR = "Jumlah IDR";
                  ammountXAU = "Jumlah XAU";
                } else if (list.table === "sells") {
                  judul = "Penjualan XAU";
                  total = list.obj.price;
                  jumlahIDR = list.obj.amount_idr;
                  jumlahXAU = list.obj.amount_xau;
                  ammountIDR = "Jumlah IDR";
                  ammountXAU = "Jumlah XAU";
                }

                const getDate = new Date(list.obj.created_at);
                const newDate = getDate
                  .toLocaleDateString("id-ID", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                  .replace(/ /g, " ");

                return (
                  <HistoryTransaction
                    key={list.id}
                    date={newDate}
                    judul={judul}
                    total={total}
                    status={list.obj.status}
                    jumlahIDR={jumlahIDR}
                    jumlahXAU={jumlahXAU}
                    id={list.obj.id}
                    ammountIDR={ammountIDR}
                    ammountXAU={ammountXAU}
                    txhash={txhash}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentTransactions;
