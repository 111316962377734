import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { forgotPassword } from "../../features/auth/authService";
import EmailIcon from "../../assets/icons/email-icon.png";

// modal custom
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function ModalForgotPassword({ show }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [messageForgotPassword, setMessageForgotPassword] = useState("");
  const [isSuccessRes, setIsSuccessRes] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [email, setEmmail] = useState("");
  const [isValid, setIsvalid] = useState(false);

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  // handle modal
  let subtitle;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    // handleShow();
    if (isSuccessRes) {
      navigate(`/change-password/${email}`);
    } else {
      setIsOpen(false);
    }
  }

  const onChange = (e) => {
    console.log(e.target.value);
    const input = e.target.value;

    setEmmail(input);
  };

  const resetErr = () => {
    setMessageForgotPassword("");
    setErrMsg("");
  };

  const submitForm = () => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailPattern.test(email)) {
      setIsvalid(false);
      setErrMsg("Email tidak valid");
    } else {
      setIsvalid(true);
      fetchAPI();
    }
  };

  const fetchAPI = async () => {
    const datas = {
      email: email,
    };

    try {
      const res = await forgotPassword(datas);
      if (res.success) {
        setIsSuccessRes(true);
        setTimeout(() => {
          navigate(`/change-password/${email}`);
        }, 5000);
      } else {
        setIsSuccessRes(false);
      }
      setMessageForgotPassword(res.msg);
    } catch (err) {
      console.log(err);
      setErrMsg(err.response.data.msg);
      console.table(err.response.data.success);
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {isSuccessRes ? (
          <div className="w-[300px] flex flex-col items-center gap-4">
            <img src={EmailIcon} alt="" />
            <h2 className="text-sm text-center">{messageForgotPassword}</h2>

            <button
              onClick={() => closeModal("close")}
              className="bg-white shadow-btn-shadow rounded-lg text-sm w-fit px-3 h-7 hover:bg-slate-200"
            >
              {t('change_password')}
            </button>
          </div>
        ) : (
          <div className="w-[300px] flex flex-col items-center">
            <span className="my-4" ref={(_subtitle) => (subtitle = _subtitle)}>
              <h2 className="flex justify-center mb-2 font-bold text-black text-lg">
              {t('forgot_password_title')}
              </h2>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="forgotPassword"
                >
                  Email
                </label>
                <input
                  onClick={resetErr}
                  onChange={onChange}
                  id="forgotPassword"
                  name="email"
                  type="text"
                  placeholder="Email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errMsg && (
                  <span className="text-sm text-pink-700 mt-4">{errMsg}</span>
                )}
              </div>
            </span>
            <div className="w-full flex justify-end mt-3 gap-2">
              <button
                onClick={submitForm}
                className="bg-btn-gold shadow-btn-shadow rounded-lg text-sm w-16 h-7"
              >
                {t('send')}
              </button>
              <button
                onClick={closeModal}
                className="bg-white shadow-btn-shadow rounded-lg text-sm w-16 h-7 hover:bg-slate-200"
              >
                {t('close')}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default ModalForgotPassword;
