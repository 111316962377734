import React from "react";
import { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

import ImageAlert from "../../../assets/images/auth/kyc-alert.png";
import Cookies from "js-cookie";

// modal custom
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function ModalSession() {
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(true);

  const handleLogin = () => {
    Cookies.remove("user");
    Cookies.remove("jwt");
    window.location.href = "/login";
  }

  // handle modal
  let subtitle;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex flex-col items-center z-[]">
          <img src={ImageAlert} alt="alert" />
          <span className="mt-4" ref={(_subtitle) => (subtitle = _subtitle)}>
            <h2 className="text-[#344054] font-bold max-w-md text-center">
              {t('modal_session_title')}
            </h2>
          </span>
          <p className="text-[#807E75]">{t('modal_session_desc')}</p>
          <div className="w-full flex justify-end mt-3">
            <button
              onClick={handleLogin}
              className="bg-[#AE9B46] rounded-lg text-sm text-white font-semibold w-full h-7 flex justify-center items-center"
            >
              {t('login')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalSession;
