import React, { useEffect, useState } from "react";

import "./SuccessAnimation.css";

function SuccessAnimation() {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Menambah kelas animate-success setelah sejumlah waktu untuk memicu animasi
    setTimeout(() => {
      setAnimate(true);
    }, 500);
  }, []);

  return (
    <div className="flex flex-col gap-3 justify-center items-center">
      <div
        className={`bg-green-500 text-white rounded-full p-8 ${
          animate ? "animate-success" : ""
        }`}
      >
        <svg
          className="w-16 h-16"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 13l4 4L19 7"
          ></path>
        </svg>
      </div>
      <div className="ml-4 text-3xl font-semibold text-green-500">Success!</div>
    </div>
  );
}

export default SuccessAnimation;
