import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import NotLogin from "../components/auth/NotLogin";
import { useLocation } from "react-router-dom";
import { BreadcrumbGoBack } from "../components/breadcrumb/Breadcrumb";
// import { createDeposit, getBankData, reset } from "../features/deposit/idr/depositIDRSlice";

function TutorialTransfer() {
  const { user } = useSelector((state) => state.auth);
  const { depositIDR, bankID, bankName } = useSelector((state) => state.depositIDR);

  // const location = useLocation();
  // const prevData = location.state;
  // console.log(prevData);

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    // if (isError) {
    //   console.log(message);
    // }
    // dispatch(reset());
    // dispatch(createDeposit());
    // dispatch(getBankData());
    // getBankData();
  }, [user, depositIDR, dispatch]);

  // console.log(depositIDR);

  // get bank data for payment
  let bank;
  if (depositIDR) {
    bank = bankName.find((bank) => bank.id === depositIDR.data.bank_id);
  }

  return (
    <>
      {user.success ? (
        <>
          <BreadcrumbGoBack title={"Detail Transaction"} />
          <div className="p-4">
            <div className="px-6 mb-8">
              <div className="border border-slate-400 rounded-lg mx-auto md:max-w-xl px-4 md:px-8 pb-8">
                <div className="mb-4 mt-4">
                  <h3>Transfer ke Virtual Account</h3>
                  <h3 className="font-semibold">{bank.number_rekening}</h3>
                </div>
                <div className="mb-4">
                  <h3>Total Bayar</h3>
                  <h3 className="font-semibold">{depositIDR && depositIDR.data.total}</h3>
                </div>
                <div className="border border-slate-400 rounded-lg px-4 py-4 flex">
                  <span className="px-4">icon</span>
                  <p>Pastikan nomor virtual account yang dimasukkan sudah benar. Xaurius tidak bertanggung jawab jika terjadi kesalahan pengisian data.</p>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <h2>Instruksi Pembayaran</h2>
              <div className="flex">
                <span className="px-4">icon</span>
                <span>ATM</span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between">
              <button className="w-full bg-[#FCCF08] hover:bg-blue-70 text-black font-bold py-2 px-4 mb-3 rounded-[34px] focus:outline-none focus:shadow-outline" type="submit">
                Cek Status Pembayaran
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <NotLogin />
        </>
      )}
    </>
  );
}

export default TutorialTransfer;
