import axios from "axios";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// create deposit
const createDeposit = async (depositData, token) => {
  console.log(depositData, token);
  const config = {
    headers: {
      JWT: token,
    },
  };

  const response = await axios.post(API_URL + "deposits/create", depositData, config);

  return response.data;
};

const depositIDRService = {
  createDeposit,
};

export default depositIDRService;
