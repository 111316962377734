import React from "react";
import { HiOutlineClock } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import iconSmartphone from "../../assets/icons/smartphone.svg";
import iconTime from "../../assets/icons/time.svg";
import iconUsers from "../../assets/icons/users.svg";
import iconZap from "../../assets/icons/zap.svg";

function Feature() {
  const { t } = useTranslation();

  return (
    <>
      <div className="px-4 md:px-20 mb-14">
        <div className="md:flex mb-12 justify-center">
          <div className="flex flex-col items-center px-8">
            <h2 className="text-2xl font-semibold">{t('feature_title')}</h2>
            <p className="text-gray-500 text-center">{t('feature_desc')}</p>
          </div>
        </div>
        <div className="md:flex md:flex-wrap">
          <div className="md:basis-2/4 px-8 md:px-0 md:pr-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconTime} />
            </div>
            <h3 className="text-lg font-semibold mb-2">{t('feature_a_title')}</h3>
            <p className="text-gray-500">{t('feature_a_desc')}</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pl-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconZap} />
            </div>
            <h3 className="text-lg font-semibold mb-2">{t('feature_b_title')}</h3>
            <p className="text-gray-500">{t('feature_b_desc')}</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pr-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconSmartphone} />
            </div>
            <h3 className="text-lg font-semibold mb-2">{t('feature_c_title')}</h3>
            <p className="text-gray-500">{t('feature_c_desc')}</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pl-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconUsers} />
            </div>
            <h3 className="text-lg font-semibold mb-2">{t('feature_d_title')}</h3>
            <p className="text-gray-500">{t('feature_d_desc')}</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pr-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconSmartphone} />
            </div>
            <h3 className="text-lg font-semibold mb-2">{t('feature_e_title')}</h3>
            <p className="text-gray-500">{t('feature_e_desc')}</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pl-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconUsers} />
            </div>
            <h3 className="text-lg font-semibold mb-2">{t('feature_f_title')}</h3>
            <p className="text-gray-500">{t('feature_f_desc')}</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pr-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconSmartphone} />
            </div>
            <h3 className="text-lg font-semibold mb-2">{t('feature_g_title')}</h3>
            <p className="text-gray-500">{t('feature_g_desc')}</p>
          </div>
          <div className="md:basis-2/4 px-8 md:px-0 md:pl-8 mb-8">
            <div className="h-[48px]">
              <img className="h-full" src={iconUsers} />
            </div>
            <h3 className="text-lg font-semibold mb-2">{t('feature_h_title')}</h3>
            <p className="text-gray-500">{t('feature_h_desc')}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Feature;
