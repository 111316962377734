import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiChevronLeft } from "react-icons/hi2";
import SuccessAnimation from "../../animations/SuccessAnimation";

export const SuccessKYC = ({ state, status }) => {
  const { t } = useTranslation();
  // console.log(state, status);
  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col items-center p-4 mb-4">
        <h1 className="text-xl font-semibold ml-4">{t('kyc_success_title')}</h1>
        <SuccessAnimation />
        <span>{t('kyc_success_desc')} {state ? state.formData.amount_xau : null} XAU.</span>
        <Link to={"/history"}>
          <span className="font-semibold">{t('kyc_success_link')}</span>
        </Link>
      </div>
    </>
  );
};
