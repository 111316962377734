import { createSlice } from "@reduxjs/toolkit";
import { blogArticles } from "../../data/blogArticles";
import {
  handleFilterArticle,
  handlePagination,
  handleMaxPage,
  handleSearchArticle,
} from "./blogHelper";

const itemsPerPage = 8;

const initialState = {
  allArticles: blogArticles,
  displayedArticles: blogArticles.slice(0, itemsPerPage),
  page: 1,
  maxPage: handleMaxPage(blogArticles, itemsPerPage),
};

const blogSlice = createSlice({
  name: "blog",
  initialState: initialState,
  reducers: {
    searchArticle: (state, action) => {
      state.page = 1;
      state.allArticles = handleSearchArticle(
        initialState.allArticles,
        action.payload
      );
      state.displayedArticles = handlePagination(
        state.allArticles,
        state.page,
        itemsPerPage
      );
      state.maxPage = handleMaxPage(state.allArticles, itemsPerPage);
    },
    filterArticle: (state, action) => {
      state.page = 1;
      state.allArticles = handleFilterArticle(
        initialState.allArticles,
        action.payload
      );
      state.displayedArticles = handlePagination(
        state.allArticles,
        state.page,
        itemsPerPage
      );
      state.maxPage = handleMaxPage(state.allArticles, itemsPerPage);
    },
    pagination: (state, action) => {
      state.page = action.payload;
      state.displayedArticles = handlePagination(
        state.allArticles,
        state.page,
        itemsPerPage
      );
    },
  },
});

export const { searchArticle, filterArticle, pagination } = blogSlice.actions;
export default blogSlice.reducer;
