import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import formatToRupiah from "./helper/formatToRupiah";
import getChart from "../features/chart/getChart";

function PriceGold() {
  const { t } = useTranslation();
  const [persenGold, setPersenGold] = useState(null);
  const [goldPrice, setGoldPrice] = useState(null);
  const [high, setHigh] = useState(null);
  const [low, setLow] = useState(null);
  const priceGold = [
    {
      currency: "IDR",
      symbol: "Rp",
      price: 962302,
      unit: "gram",
      increment: 12,
    },
  ];

  useEffect(() => {
    const token = Cookies.get("jwt");
    const getChartData = async () => {
      try {
        const res = await getChart(token);

        if (res && res.length > 0) {
          const dataNow = res[0];
          const closePriceNow = parseFloat(dataNow.cclose);
          setGoldPrice(closePriceNow);

          const highestPrice = parseFloat(dataNow.chigh);
          setHigh(highestPrice);

          // Jika hanya ada satu harga, tidak perlu menghitung persentase perubahan
          if (res.length > 1) {
            const dataPrev = res[1];
            const closePricePrev = parseFloat(dataPrev.cclose);
            const lowestPrice = parseFloat(dataPrev.clow);
            setLow(lowestPrice);

            if (closePricePrev !== 0) {
              const percentageChange =
                ((closePriceNow - closePricePrev) / Math.abs(closePricePrev)) *
                100;
              setPersenGold(percentageChange.toFixed(2));
            } else {
              console.log(
                "Tidak dapat menghitung persentase perubahan karena harga sebelumnya adalah 0."
              );
            }
          } else {
            // Hanya satu data harga, set low dengan data yang ada
            setLow(highestPrice);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (token) {
      getChartData();
    }
  }, []);

  return (
    <div className="rounded-xl shadow-lg bg-gradient-to-r from-gold1 to-gold2 h-[210px] mb-8">
      <div className="flex flex-col h-full px-8 py-6">
        <h3 className="text-md">{t('gold_price')}</h3>
        <div className="flex mb-8">
          <div className="flex">
            <h1 className="font-semibold text-2xl">
              {formatToRupiah(goldPrice)}
            </h1>
            <h1 className="font-semibold text-2xl">/{priceGold[0].unit}</h1>
          </div>
          <div className="flex grow justify-end">
            {persenGold !== null && (
              <div
                className={`flex items-center rounded-full px-2 ${
                  persenGold >= 0 ? "bg-green-600" : "bg-red-600"
                } text-white`}
              >
                <span className="rounded-full w-[8px] h-[8px] mx-2 bg-white"></span>
                <span>{persenGold}</span>
                <span>%</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex grow items-end">
          <div className="grow pr-6">
            <h5 className="text-xs mb-2">{t('buy_price')}</h5>
            <div className="flex">
              <h1 className="font-medium text-xs">{formatToRupiah(high)}</h1>
              <h1 className="font-medium text-xs">/{priceGold[0].unit}</h1>
            </div>
          </div>
          <div className="grow pl-6">
            <h5 className="text-xs mb-2">{t('sell_price')}</h5>
            <div className="flex">
              <h1 className="font-medium text-xs">{formatToRupiah(low)}</h1>
              <h1 className="font-medium text-xs">/{priceGold[0].unit}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriceGold;
