import React from "react";
import Header from "../../components/client/Header";
import Footer from "../../components/client/Footer";
import ContentTOS from "../../components/pages/contentTOS";

function TermsOfService() {
  return (
    <>
      <Header />
      <ContentTOS />
      <Footer />
    </>
  );
}

export default TermsOfService;
