import axios from "axios";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL_PREFIX = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// TODO: FILTER RULES HARUS DIJADIKAN DYNAMIC
const API_URL = `${API_URL_PREFIX}charts`;

// get service
const getChart = async (token) => {
  const config = {
    headers: {
      JWT: token,
    },
  };

  const response = await axios.get(API_URL, config);

  return response.data.data.datas;
};

export default getChart;
