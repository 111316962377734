import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bankUserService from "./bankUserService";
import Cookies from "js-cookie";

const initialState = {
  message: "",
  statusKYC: false,
  bankUser: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
};

// get bank user
export const getBankUser = createAsyncThunk("bank/user", async (_, thunkAPI) => {
  // console.log(thunkAPI);
  try {
    const token = thunkAPI.getState().auth.user.data.user.user_jwt_token; // get token from auth state
    // console.log(token);
    return await bankUserService.getBankUser(token);
  } catch (error) {
    // console.log(error);
    const message = (error.response && error.response.data && error.response.data.msg) || error.message || error.toString();

    if (error.response.status === 401) {
      Cookies.set("user", JSON.stringify(error.response.data), { expires: 2 });
    }

    return thunkAPI.rejectWithValue(message); // return axios error to message state
  }
});

// create bank user
export const createBankUser = createAsyncThunk("bank/user/create", async (bankUserData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.data.user.user_jwt_token;
    // console.log(token);
    return await bankUserService.createBankUser(bankUserData, token);
  } catch (error) {
    console.log(error);
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    if (error.response.status === 401) {
      Cookies.set("user", JSON.stringify(error.response.data), { expires: 2 });
    }

    return thunkAPI.rejectWithValue(message);
  }
});

// update bank user
export const updateBankUser = createAsyncThunk("bank/user/update", async (bankUserData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.data.user.user_jwt_token;
    // console.log(token);
    return await bankUserService.updateBankUser(bankUserData, token);
  } catch (error) {
    console.log(error);
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

    if (error.response.status === 401) {
      Cookies.set("user", JSON.stringify(error.response.data), { expires: 2 });
    }

    return thunkAPI.rejectWithValue(message);
  }
});

export const bankUserSlice = createSlice({
  name: "bankUser",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBankUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = state.isSuccess === false ? true : false;
        state.isSuccess = action.payload.success;
        state.message = action.payload.msg;
        state.statusKYC = action.payload.success;
        state.bankUser = action.payload.data;
      })
      .addCase(getBankUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusKYC = action.payload.success;
        state.bankUser = null;
      })
      .addCase(createBankUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBankUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = state.isSuccess === false ? true : false;
        state.isSuccess = action.payload.success;
        state.message = action.payload.msg;
        // state.statusKYC = action.payload.success;
        state.bankUser = action.payload;
      })
      .addCase(createBankUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.statusKYC = action.payload.success;
        state.bankUser = null;
      })
      .addCase(updateBankUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBankUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = state.isSuccess === false ? true : false;
        state.isSuccess = action.payload.success;
        state.message = action.payload.msg;
        // state.statusKYC = action.payload.success;
        state.bankUser = action.payload;
      })
      .addCase(updateBankUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.statusKYC = action.payload.success;
        state.bankUser = null;
      });
  },
});

export const { reset } = bankUserSlice.actions;
export default bankUserSlice.reducer;
