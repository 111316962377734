import React from "react";
import Nav from "./BlogNav";
import Content from "./BlogContent";
import BlogPagination from "./BlogPagination";

function BlogMain() {
  return (
    <>
      <div className="px-4 md:px-8 py-10 md:py-24 divide-y">
        <div className="flex mb-16 gap-14">
          <Nav />
          <Content />
        </div>
        <BlogPagination />
      </div>
    </>
  );
}

export default BlogMain;
