import React, { useEffect } from "react";
import Header from "../../components/client/Header";
import Footer from "../../components/client/Footer";
import Hero from "../../components/home/Hero";
import Price from "../../components/home/Price";
import Partner from "../../components/home/Partner";
import WhatIsXaurius from "../../components/home/WhatIsXaurius";
import Feature from "../../components/home/Feature";
import BlogHome from "../../components/home/BlogHome";
import StartJourney from "../../components/home/StartJourney";

function Homepage() {
  useEffect(() => {
    document.title = "Xaurius | Home";
  }, []);

  return (
    <>
      <Header />
      <Hero />
      <Price />
      <Partner />
      <WhatIsXaurius />
      <Feature />
      <BlogHome />
      <StartJourney />
      <Footer />
    </>
  );
}

export default Homepage;
