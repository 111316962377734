import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import SuccessAnimation from "../../animations/SuccessAnimation";

// modal custom
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function ModalAddBank() {
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(true);
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [loader, setIsLoader] = useState(false);
  const [formData, setFormData] = useState({
    nama_akun: "",
    nama_bank: "",
    nomor_rekening: "",
  });

  const { nama_akun, nama_bank, nomor_rekening } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // handle modal
  let subtitle;

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    window.location.reload();
  }

  const onSubmit = (e) => {
    e.preventDefault();

    fecthApi();
  };

  const fecthApi = async () => {
    const urlDev = process.env.REACT_APP_API_URL_DEV;
    const urlProd = process.env.REACT_APP_API_URL_PROD;

    const bankData = {
      nama_akun,
      nama_bank,
      nomor_rekening,
    };

    const API_URL =
      process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

    try {
      setIsLoader(true);
      const apiUrl = `${API_URL}bank_users/create`;

      const requestBody = {
        ...bankData,
      };
      const token = Cookies.get("jwt");

      const config = {
        headers: {
          JWT: token,
        },
      };

      const response = await axios.post(apiUrl, requestBody, config);
      console.log("Response:", response.data);

      if (response.data.success) {
        setIsSuccess(true);
        setResMsg(response.data.msg);
        console.log("success");
      } else {
        setIsSuccess(false);
        setResMsg(response.data.msg);
        console.log("gagal");
      }
      setIsLoader(false);

      return response.data;
    } catch (error) {
      setIsSuccess(false);
      setResMsg("Gagal");
      console.error("Error:", error);
      throw error;
    }
  };

  const clearErr = () => {
    setResMsg("");
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="w-full lg:w-[500px]">
          {!isSuccess ? (
            <div className="w-full flex flex-col items-center">
              <div className="w-full flex justify-between items-center">
                <span ref={(_subtitle) => (subtitle = _subtitle)}>
                  <h2 className="text-[#344054] font-bold max-w-md text-center">
                    {t('modal_add_bank_title')}?
                  </h2>
                </span>

                <button
                  onClick={closeModal}
                  className="text-[#344054] text-sm w-16 h-7 hover:text-blue-500"
                >
                  <svg
                    className="unf-icon"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="#2E3137"
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                  >
                    <path d="M13.06 12l7.47-7.47a.75.75 0 10-1.06-1.06L12 10.94 4.53 3.47a.75.75 0 10-1.06 1.06L10.94 12l-7.47 7.47a.75.75 0 001.06 1.06L12 13.06l7.47 7.47a.75.75 0 001.06-1.06L13.06 12z"></path>
                  </svg>
                </button>
              </div>
              {/* form add bank */}
              <form onSubmit={onSubmit} className="w-full">
                <div className="mb-4">
                  <label
                    className="block text-[#344054] text-sm mb-2"
                    htmlFor="nama_akun"
                  >
                    {t('account_name')}
                  </label>
                  <input
                    required
                    id="nama_akun"
                    name="nama_akun"
                    type="text"
                    value={nama_akun}
                    onChange={onChange}
                    onClick={clearErr}
                    placeholder={t('account_name_desc')}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-[#344054] text-sm mb-2"
                    htmlFor="nama_bank"
                  >
                    {t('bank_name')}
                  </label>
                  <input
                    required
                    id="nama_bank"
                    name="nama_bank"
                    type="text"
                    value={nama_bank}
                    onChange={onChange}
                    onClick={clearErr}
                    placeholder={t('bank_name_desc')}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-[#344054] text-sm mb-2"
                    htmlFor="nomor_rekening"
                  >
                    {t('account_number')}
                  </label>
                  <input
                    required
                    id="nomor_rekening"
                    name="nomor_rekening"
                    type="text"
                    value={nomor_rekening}
                    onChange={onChange}
                    onClick={clearErr}
                    inputMode="numeric"
                    placeholder={t('account_number_desc')}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full flex justify-end mt-3">
                  <button
                    type="submit"
                    className="bg-btn-gold rounded-lg text-sm font-bold w-full px-2 text-[#344054] h-10"
                  >
                    {t('add_account')}
                  </button>
                </div>
              </form>

              {/* response msg */}
              {resMsg && (
                <div className="w-full bg-red-400 text-white rounded-md p-2 mt-3">
                  <p>{resMsg}</p>
                </div>
              )}
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center gap-3">
              <SuccessAnimation />
              <span className="text-[#344054] text-center">{resMsg}</span>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ModalAddBank;
