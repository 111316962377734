import React from "react";

function TotalAssets() {
  const totalAssets = [
    {
      currency: "XAU",
      symbol: "XAU",
      price: 0,
      unit: "pcs",
      increment: -2,
    },
  ];
  // console.log(totalAssets);

  return (
    <>
      <div className="rounded-xl shadow-lg bg-gradient-to-r from-gold1 to-gold2 h-[210px] mb-8">
        <div className="flex flex-col h-full px-8 py-6">
          <h3 className="text-md">Total Assets</h3>
          <div className="flex mb-8">
            <div className="flex">
              <h1 className="font-semibold text-xl flex items-end pr-1">{totalAssets[0].symbol}</h1>
              <h1 className="font-semibold text-2xl">{totalAssets[0].price}</h1>
            </div>
            <div className="flex grow justify-end">
              <div className="flex items-center rounded-full px-2 bg-red-600 text-white">
                <span className="rounded-full w-[8px] h-[8px] mx-2 bg-white"></span>
                <span>{totalAssets[0].increment}</span>
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TotalAssets;
