import React from "react";
import { useTranslation } from "react-i18next";

function ContentFAQ() {
  const { t } = useTranslation();

  return (
    <>
      <figure className="h-[320px] overflow-hidden relative before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-slate-700 before:opacity-70 mb-20 -z-10">
        <img src="images/1.jpg" alt="" />
        <h1 className="font-bold text-2xl text-white z-[2] absolute top-0 left-0 w-full h-full flex items-center justify-center">F.A.Q</h1>
      </figure>
      <div className="px-4 md:px-8 lg:px-20 mb-20">
        <p>{t('tos_desc')}</p>
        <ol className="list-decimal mt-2 pl-8">
          <li className="mb-2">
            <h3 className="font-semibold">{t('tos_a_title')}</h3>
            <p>{t('tos_a_desc')}</p>
          </li>
          <li className="mb-2">
            <h3 className="font-semibold">{t('tos_b_title')}</h3>
            <p>{t('tos_b_desc')}</p>
          </li>
          <li className="mb-2">
            <h3 className="font-semibold">{t('tos_c_title')}</h3>
            <p>{t('tos_c_desc')}</p>
          </li>
          <li className="mb-2">
            <h3 className="font-semibold">{t('tos_d_title')}</h3>
            <p>{t('tos_d_desc')}</p>
          </li>
          <li className="mb-2">
            <h3 className="font-semibold">{t('tos_e_title')}</h3>
            <p>{t('tos_e_desc')}</p>
          </li>
          <li className="mb-2">
            <h3 className="font-semibold">{t('tos_f_title')}</h3>
            <p>{t('tos_f_desc')}</p>
          </li>
          <li className="mb-2">
            <h3 className="font-semibold">{t('tos_g_title')}</h3>
            <p>{t('tos_g_desc')}</p>
          </li>
          <li className="mb-2">
            <h3 className="font-semibold">{t('tos_h_title')}</h3>
            <p>{t('tos_h_desc')}</p>
          </li>
          <li className="mb-2">
            <h3 className="font-semibold">{t('tos_i_title')}</h3>
            <p>{t('tos_i_desc')}</p>
          </li>
        </ol>
      </div>
    </>
  );
}

export default ContentFAQ;
