import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import balanceIDRReducer from "../features/balance/idr/balanceIDRSlice";
import bankUserReducer from "../features/banks/bankUser/bankUserSlice";
import bankXAUReducer from "../features/banks/bankXAU/bankXAUSlice";
import buyXAUReducer from "../features/buy/xau/buyXAUSlice";
import chartReducer from "../features/chart/chartSlice";
import depositIDRReducer from "../features/deposit/idr/depositIDRSlice";
import depositXAUReducer from "../features/deposit/xau/depositXAUSlice";
import withdrawIDRReducer from "../features/withdraw/idr/withdrawIDRSlice";
import withdrawXAUReducer from "../features/withdraw/xau/withdrawXAUSlice";
import evmReducer from "../features/evm/evmSlice";
import otpReducer from "../features/otp/otpSlice";
import kycReducer from "../features/kyc/kycSlice";
import profileReducer from "../features/profile/profileSlice";
import updateProfileReducer from "../features/profile/updateProfileSlice";
import profileUpdateReducer from "../features/profile/updateProfileSlice";
import sellXAUReducer from "../features/sell/xau/sellXAUSlice";
import historyReducer from "../features/history/historySlice";
import uploadFile from "../features/deposit/upload/uploadSlice";
import lastPriceSlice from "../features/price/lastPriceSlice";
import networksReduser from "../features/networks/networksSlice";
import addressReduser from "../features/address/addressSlice";
import blogReducer from "../features/blog/blogSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    balanceIDR: balanceIDRReducer,
    bankUser: bankUserReducer,
    bankXAU: bankXAUReducer,
    buyXAU: buyXAUReducer,
    chart: chartReducer,
    depositIDR: depositIDRReducer,
    depositXAU: depositXAUReducer,
    withdrawIDR: withdrawIDRReducer,
    withdrawXAU: withdrawXAUReducer,
    evm: evmReducer,
    otp: otpReducer,
    kyc: kycReducer,
    profile: profileReducer,
    updateProfile: updateProfileReducer,
    profileUpdate: profileUpdateReducer,
    sellXAU: sellXAUReducer,
    history: historyReducer,
    upload: uploadFile,
    lastPrice: lastPriceSlice,
    networks: networksReduser,
    address: addressReduser,
    blog: blogReducer,
  },
});
