import axios from "axios";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// create sell
const createSell = async (sellXAUData, token) => {
  const config = {
    headers: {
      JWT: token,
    },
  };
  console.log(sellXAUData, token, config);

  const response = await axios.post(API_URL + "sells/create", sellXAUData, config);

  return response.data;
};

const sellXAUService = {
  createSell,
};

export default sellXAUService;
