import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiChevronLeft } from "react-icons/hi2";
import { useSelector } from "react-redux";

export const PendingWithdrawIDR = ({ status }) => {
  const { t } = useTranslation();
  const withdrawIDR = useSelector((state) => state.withdrawIDR);
  // console.log(status);
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col items-center p-4 mb-4">
        <h1 className="text-xl font-semibold ml-4">{t('pending_withdraw_title')}</h1>
        <span>{t('pending_withdraw_desc')}</span>
        <Link to={"/history"}>
          <span className="font-semibold">{t('pending_withdraw_link')}</span>
        </Link>
      </div>
    </>
  );
};
