import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;
const API_URL = process.env.REACT_APP_ENV === "development" ? urlDev : urlProd;

const token = Cookies.get("jwt");
const config = {
  headers: {
    JWT: token,
  },
};

export const fetchAddress = createAsyncThunk(
  "address/fetchAddress",
  async () => {
    try {
      const response = await axios.get(
        `${API_URL}ccdeposits/request-address`,
        config
      );
      return response.data;
    } catch (error) {
      throw Error(error.message);
    }
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState: {
    message: "",
    walletAddress: "",
    userId: null,
    status: "idle",
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.walletAddress = "";
      state.userId = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddress.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAddress.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.status = "succeeded";
          state.userId = action.payload.data.user_id;
          state.walletAddress = action.payload.data.wallet_address;
        } else {
          state.status = "failed";
          state.error = action.payload.msg;
        }
      })
      .addCase(fetchAddress.rejected, (state, action) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});

export const selectWalletAddress = (state) => state.address.walletAddress;
export const selectUserId = (state) => state.address.userId;
export const selectAddressStatus = (state) => state.address.status;
export const selectErr = (state) => state.address.error;
export const { resetState } = addressSlice.actions;

export default addressSlice.reducer;
