import React from "react";
import { useTranslation } from "react-i18next";
import imageLogoBappebti from "../../assets/images/logo-bappebti.png";
import imageLogoLbma from "../../assets/images/logo-lbma.png";
import imageLogoAntam from "../../assets/images/logo-antam.png";

function Partner() {
  const { t } = useTranslation();

  return (
    <>
      <div className="py-2 md:py-12 mt-8 mb-8 md:mt-8 md:mb-0">
        <div className="flex-col items-center">
          <h3 className="text-center mb-4 text-xl md:text-2xl font-semibold">{t('verified_by')}:</h3>
          <div className="items-center md:flex md:justify-center">
            <div className="md:flex">
              <div className="basis-2/6 my-8">
                <img className="mx-auto" src={imageLogoBappebti} alt="Logo Bappebti" />
              </div>
              <div className="basis-2/6 my-8">
                <img className="mx-auto" src={imageLogoLbma} alt="Logo LBMA" />
              </div>
              <div className="basis-2/6 my-8">
                <img className="mx-auto" src={imageLogoAntam} alt="Logo Antam" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Partner;
