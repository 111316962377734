import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Nav from "../components/Nav";
import NotLogin from "../components/auth/NotLogin";
import PriceGold from "../components/PriceGold";
import FormSellXAU from "../components/forms/FormSellXAU";
import { getProfile } from "../features/profile/profileSlice";
import Review from "../assets/images/review.gif";
import Cookies from "js-cookie";

import "swiper/css";
import { BreadcrumbGoBack } from "../components/breadcrumb/Breadcrumb";
import ChartGoldPrice from "../components/charts/ChartGoldPrice";
import RecentHistory from "./history/Recenthistory";

function SellXAU() {
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const { message } = useSelector((state) => state.balanceIDR);
  const [jwt, setJwt] = useState("");

  const messageStatusKYC = message;

  const dispatch = useDispatch();

  useEffect(() => {
    const jwt = Cookies.get("jwt");
    if (jwt) {
      setJwt(jwt);
    }
    dispatch(getProfile()); // check kyc status
  }, [user, dispatch]);

  return (
    <>
      {jwt ? (
        <>
          {profile.data && profile.data.status_kyc === "approved" ? (
            <>
              <Nav />
              <div className="md:pl-[30vw] xl:pl-[20vw]">
                <div className="pt-6 pr-6 pb-[8rem] pl-6 bg-[#F2F2ED] min-h-screen rounded-tl-3xl rounded-bl-3xl">
                  <PriceGold />
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="col-span-2">
                      <ChartGoldPrice />
                    </div>

                    <div className="h-full">
                      <FormSellXAU />
                    </div>
                  </div>

                  {/* <SellXAUHistory /> */}
                  <RecentHistory histories={"sells"} />
                </div>
              </div>
            </>
          ) : (
            <>
              <BreadcrumbGoBack />
              <div className="flex flex-col justify-center md:items-center md:w-2/4 xl:w-2/6 mx-auto h-screen">
                <div className="flex flex-col items-center px-8 mb-3">
                  <img src={Review} alt="" />
                  <p>{messageStatusKYC}.</p>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <NotLogin />
        </>
      )}
    </>
  );
}

export default SellXAU;
