import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import buyXAUService from "./buyXAUService";
import Cookies from "js-cookie";

// get user
const encodeString = Cookies.get("user");
const decodedString = encodeString ? decodeURIComponent(encodeString) : null;
const user = encodeString ? JSON.parse(decodedString) : null;

const initialState = {
  user: user ? user : null,
  buyXAU: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// create buy
export const createBuy = createAsyncThunk("buy/create", async (buyXAUData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.data.user.user_jwt_token;
    console.log(token);
    return await buyXAUService.createBuy(buyXAUData, token);
  } catch (error) {
    const message = error.response?.data?.msg || error.message || error.toString();
    return thunkAPI.rejectWithValue({ success: false, msg: message });
  }
});

export const buyXAUSlice = createSlice({
  name: "buyXAU",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBuy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createBuy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = state.isSuccess === false ? true : false;
        state.isSuccess = action.payload.success;
        state.message = action.payload.msg;
        state.user = action.payload;
        state.buyXAU = action.payload;
      })
      .addCase(createBuy.rejected, (state, action) => {
        const encodeString = Cookies.get("user");
        const decodedString = encodeString ? decodeURIComponent(encodeString) : null;
        const user = encodeString ? JSON.parse(decodedString) : null;

        console.log(action.payload)
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.msg;
        state.user = user;
        state.buyXAU = action.payload;
      });
  },
});

export const { reset } = buyXAUSlice.actions;
export default buyXAUSlice.reducer;
