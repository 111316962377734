import React from "react";
import { useTranslation } from "react-i18next";

function Price() {
  const { t } = useTranslation();

  return (
    <>
      <div className="px-8 py-8 lg:pt-24 lg:pb-12 mt-8 lg:mt-24">
        <div className="md:flex justify-center">
          <div className="basis-2/6 mx-8 mb-12 text-center">
            <div>
              <h4 className="text-lg">{t('price_update')}</h4>
              <h2 className="text-4xl font-semibold text-sub-heading">
                <span>$</span>
                <span>1,828,72</span>
              </h2>
            </div>
          </div>
          <div className="basis-2/6 mx-8 mb-12 text-center">
            <div>
              <h4 className="text-lg">{t('market_cap')}</h4>
              <h2 className="text-4xl font-semibold text-sub-heading">
                <span>$</span>
                <span>1,828,72</span>
              </h2>
            </div>
          </div>
          <div className="basis-2/6 mx-8 mb-12 text-center">
            <div>
              <h4 className="text-lg">{t('gold_kilogram')}</h4>
              <h2 className="text-4xl font-semibold text-sub-heading">
                <span>1.068</span>
                <span>Kg</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Price;
