import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { HiBars3, HiChevronDown, HiXMark } from "react-icons/hi2";
import { ToastContainer, toast } from "react-toastify";
import Button from "../utilities/button";
import { logout, reset } from "../../features/auth/authSlice";
import { getProfile } from "../../features/profile/profileSlice";
import { AiFillDashboard, AiOutlineLogout } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import idIcon from "../../assets/icons/country/id.svg";
import usIcon from "../../assets/icons/country/us.svg";

function MenuHeader() {
  const { t, i18n } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);

  const [showMenu, setShowMenu] = useState(false); // toggle menu
  const [showMenuSub, setShowMenuSub] = useState(false); // toggle menu
  const [showMenuLanguage, setShowMenuLanguage] = useState(false); // toggle menu

  const currentLanguage = i18n.language;

  const changeLanguage = (lang) => {
    localStorage.setItem("language", lang);
    window.location.reload();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
  }, [user, dispatch]);

  const onClickShow = (e) => {
    e.preventDefault();

    setShowMenu(!showMenu);
  };

  const onClickClose = (e) => {
    e.preventDefault();

    setShowMenu(!showMenu);
  };

  const onClickSubMenu = (e) => {
    e.preventDefault();

    setShowMenuSub(!showMenuSub);
  };

  const onClickLanguageMenu = (e) => {
    e.preventDefault();

    setShowMenuLanguage(!showMenuLanguage);
  };

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    toast.success("Berhasil keluar");
  };

  return (
    <nav className="h-full">
      <div className="flex items-center justify-end lg:hidden h-full">
        <div className="px-2 mx-4 relative">
          <div
            onClick={onClickLanguageMenu}
            className="flex items-center cursor-pointer"
          >
            <div className="basis-3/6 mx-2 relative overflow-hidden w-6 h-6 rounded-full border border-slate-800">
              <img
                src={currentLanguage === "en" ? usIcon : idIcon}
                alt=""
                className="w-full h-full absolute object-cover"
              />
            </div>
            <button className="basis-1/4" onClick={onClickLanguageMenu}>
              <HiChevronDown />
            </button>
          </div>
          {showMenuLanguage ? (
            <div className="absolute top-10 left-0 ">
              <div className="bg-white border border-gray-400 rounded-md">
                <ul>
                  <li
                    onClick={() => changeLanguage("en")}
                    className="flex justify-between px-3 py-2 hover:bg-gold2"
                  >
                    <span className="text-sm">English</span>
                    <span className="relative overflow-hidden w-5 h-5 ml-3 rounded-full border border-slate-800">
                      <img
                        src={usIcon}
                        alt=""
                        className="w-full h-full absolute object-cover"
                      />
                    </span>
                  </li>
                  <li
                    onClick={() => changeLanguage("id")}
                    className="flex justify-between px-3 py-2 hover:bg-gold2"
                  >
                    <span className="text-sm">Indonesia</span>
                    <span className="relative overflow-hidden w-5 h-5 ml-3 rounded-full border border-slate-800">
                      <img
                        src={idIcon}
                        alt=""
                        className="w-full h-full absolute object-cover"
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
        </div>
        <button onClick={onClickShow}>
          <HiBars3 size={32} />
        </button>
      </div>

      {showMenu ? (
        <>
          <div className="fixed top-0 left-0 w-full h-full bg-white">
            <div className="flex justify-end py-2 px-2">
              <button onClick={onClickClose}>
                <HiXMark size={32} />
              </button>
            </div>
            <ul className="lg:flex items-center h-full">
              <li className="px-4 my-6">
                <NavLink to={"/"} exact="true">
                  <span>{t("home")}</span>
                </NavLink>
              </li>
              <li className="px-4 my-6">
                <a
                  href="https://drive.google.com/file/d/1HJr1dq7SKoIYxHXN9mkpoF_pCFvXr78m/view"
                  target="_blank"
                >
                  Whitepaper
                </a>
              </li>
              <li className="px-4 my-6">
                <span>{t("contact")}</span>
              </li>
              <li className="px-4 my-6">
                <NavLink to={"/faq"}>
                  <span>FAQ</span>
                </NavLink>
              </li>
              {user && user.success ? (
                <>
                  <li className="px-4 my-6">
                    {/* <NavLink to={"/login"}> */}
                    <div
                      onClick={onClickSubMenu}
                      className="flex items-center cursor-pointer"
                    >
                      <div className="mx-2 relative overflow-hidden w-8 h-8 rounded-full border border-slate-800">
                        <img
                          src={profile.data.image_url}
                          alt=""
                          className="w-full h-full absolute object-cover"
                        />
                      </div>
                      <div className="flex">
                        <span className="text-md font-medium mr-1">Halo,</span>
                        <span className="text-md font-medium">
                          {profile.data && profile.data.full_name
                            ? profile.data.full_name
                            : user.email}
                        </span>
                      </div>
                      <button
                        className="basis-1/4 ml-2"
                        onClick={onClickSubMenu}
                      >
                        <HiChevronDown />
                      </button>
                    </div>
                    {/* </NavLink> */}
                    {showMenuSub ? (
                      <div className="relative top-0 left-0 w-full h-full">
                        <div className="bg-white border-b border-t border-gray-400 py-2 mt-2">
                          <NavLink
                            to={"/dashboard"}
                            className="px-2 flex gap-2 items-center"
                          >
                            <AiFillDashboard />
                            <span>Dashboard</span>
                          </NavLink>
                          <div className="px-2 mt-2">
                            <button
                              onClick={onLogout}
                              className=" flex items-center gap-2"
                            >
                              <AiOutlineLogout />
                              <span>{t("logout")}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </li>
                </>
              ) : (
                <>
                  <div className="px-4 my-6">
                    <NavLink to={"/login"}>
                      <span className="text-lg font-medium">{t("login")}</span>
                    </NavLink>
                  </div>
                  <div className="px-4 my-6">
                    <NavLink to={"/register"}>
                      <Button title={t("register")} />
                    </NavLink>
                  </div>
                </>
              )}
            </ul>
          </div>
        </>
      ) : null}

      <ul className="hidden lg:flex items-center h-full justify-end">
        <li className="px-2 mx-4">
          <NavLink to={"/"}>
            <span className="text-lg font-medium">{t("home")}</span>
          </NavLink>
        </li>
        <li className="px-2 mx-4">
          <a
            href="https://drive.google.com/file/d/1HJr1dq7SKoIYxHXN9mkpoF_pCFvXr78m/view"
            target="_blank"
            className="text-lg font-medium"
          >
            Whitepaper
          </a>
        </li>
        <li className="px-2 mx-4">
          <span className="text-lg font-medium">{t("contact")}</span>
        </li>
        <li className="px-2 mx-4">
          <NavLink to={"/faq"}>
            <span className="text-lg font-medium">FAQ</span>
          </NavLink>
        </li>
        <li className="px-2 mx-4 relative">
          <div
            onClick={onClickLanguageMenu}
            className="flex items-center cursor-pointer"
          >
            <div className="basis-3/6 mx-2 relative overflow-hidden w-6 h-6 rounded-full border border-slate-800">
              <img
                src={currentLanguage === "en" ? usIcon : idIcon}
                alt=""
                className="w-full h-full absolute object-cover"
              />
            </div>
            <button className="basis-1/4 ml-2" onClick={onClickLanguageMenu}>
              <HiChevronDown />
            </button>
          </div>
          {showMenuLanguage ? (
            <div className="absolute top-10 left-0 ">
              <div className="bg-white border border-gray-400 rounded-md">
                <li
                  onClick={() => changeLanguage("en")}
                  className="flex justify-between px-3 py-2 cursor-pointer hover:bg-gold2"
                >
                  <span className="text-sm">English</span>
                  <span className="relative overflow-hidden w-5 h-5 ml-3 rounded-full border border-slate-800">
                    <img
                      src={usIcon}
                      alt=""
                      className="w-full h-full absolute object-cover"
                    />
                  </span>
                </li>
                <li
                  onClick={() => changeLanguage("id")}
                  className="flex justify-between px-3 py-2 cursor-pointer hover:bg-gold2"
                >
                  <span className="text-sm">Indonesia</span>
                  <span className="relative overflow-hidden w-5 h-5 ml-3 rounded-full border border-slate-800">
                    <img
                      src={idIcon}
                      alt=""
                      className="w-full h-full absolute object-cover"
                    />
                  </span>
                </li>
              </div>
            </div>
          ) : null}
        </li>
        {user && user.success ? (
          <>
            <div className="px-2 mx-4 relative">
              {/* <NavLink to={"/login"}> */}
              <div
                onClick={onClickSubMenu}
                className="flex items-center cursor-pointer"
              >
                <div className="basis-3/6 mx-2 relative overflow-hidden w-8 h-8 rounded-full border border-slate-800">
                  <img
                    src={profile.data?.image_url}
                    alt=""
                    className="w-full h-full absolute object-cover"
                  />
                </div>
                <div className="flex">
                  <span className="text-md font-medium mr-1">Halo,</span>
                  <span className="text-md font-medium">
                    {profile.data && profile.data.full_name
                      ? profile.data.full_name
                      : user.email}
                  </span>
                </div>
                <button className="basis-1/4 ml-2" onClick={onClickSubMenu}>
                  <HiChevronDown />
                </button>
              </div>
              {/* </NavLink> */}
              {showMenuSub ? (
                <div className="absolute top-10 left-0 w-full h-full">
                  <div className="bg-white border border-gray-400 rounded-md">
                    <li className="hover:bg-gold2 py-2">
                      <NavLink
                        to={"/dashboard"}
                        className="px-3 flex gap-2 items-center"
                      >
                        <AiFillDashboard />
                        <span>Dashboard</span>
                      </NavLink>
                    </li>
                    <li className="hover:bg-gold2 py-2">
                      <button
                        className="px-3 flex items-center gap-2"
                        onClick={onLogout}
                      >
                        <AiOutlineLogout />
                        <span>{t("logout")}</span>
                      </button>
                    </li>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <li className="px-2 mx-4">
              <NavLink to={"/login"}>
                <span className="text-lg font-medium whitespace-nowrap">
                  {t("login")}
                </span>
              </NavLink>
            </li>
            <li className="px-2 mx-4">
              <NavLink to={"/register"}>
                <Button title={t("register")} wrap={false} />
              </NavLink>
            </li>
          </>
        )}
      </ul>

      <ToastContainer />
    </nav>
  );
}

export default MenuHeader;
