import React from "react";
import { useTranslation } from "react-i18next";
import imageXauriusGold from "../../assets/images/xaurius-gold.png";

function WhatIsXaurius() {
  const { t } = useTranslation();

  return (
    <>
      <div className="h-full lg:h-[512px] mb-14 md:mb-24">
        <div className="lg:flex px-4 md:px-8 lg:px-20">
          <div className="basis-2/4">
            <div className="h-full px-8 py-2 lg:py-12">
              <h2 className="text-xl md:text-2xl font-semibold mb-4">{t('what_title')}</h2>
              <p className="md:text-lg mb-4 md:mb-8">{t('what_desc_1')}</p>
              <p className="md:text-lg mb-4 md:mb-8">{t('what_desc_2')}</p>
            </div>
          </div>
          <div className="basis-2/4">
            <div className="h-full py-2 px-8 lg:px-16">
              <img src={imageXauriusGold} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatIsXaurius;
