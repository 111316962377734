import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import { getProfile } from "../../features/profile/profileSlice";
import { getBalance } from "../../features/balance/idr/balanceIDRSlice";
import Nav from "../../components/Nav";
import NotLogin from "../../components/auth/NotLogin";
import { uploadFile } from "../../features/deposit/upload/uploadSlice";
import { useParams } from "react-router-dom";
import BreadcrumbBack from "../../components/breadcrumb/BreadcrumbBack";
// import SuccessAnimation from "../../components/animations/SuccessAnimation";

// modal custom
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function DepositUpload() {
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const isFileUploading = useSelector((state) => state.upload.uploading);
  const responseMessage = useSelector((state) => state.upload.responseMessage);

  const { user } = useSelector((state) => state.auth);
  const [image, setImage] = useState(null);
  const { id } = useParams();
  const [isDragging, setIsDragging] = useState(false);
  const [formData, setFormData] = useState({
    id: parseInt(id),
    file_deposit: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getBalance());
  }, [user, dispatch]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleImage(file);
    }
  };

  const handleImage = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      file_deposit: file,
    }));
    handleImage(file);
  };

  const onSubmit = async () => {
    try {
      await dispatch(uploadFile(formData));

      openModal();
    } catch (error) {
      console.error("Gagal mengupload file:", error);
    }
  };

  // handle modal
  let subtitle;

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    window.location.reload();
  }

  return (
    <>
      {user && user.success ? (
        <>
          <Nav />
          <div className="md:pl-[30vw] xl:pl-[20vw]">
            <div className="px-6 pb-32 h-screen bg-[#F2F2ED] rounded-t-3xl rounded-bl-3xl">
              <div className="kyc pt-6 flex gap-2 items-center cursor-pointer pb-6 border-b-2 border-[#D4D9CF]">
                <BreadcrumbBack title={"Upload Bukti Pembayaran"} />
              </div>

              {/* form upload */}
              <div className="h-full flex flex-col items-center justify-center">
                <div
                  className="h-64 w-96 mt-3 border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center relative"
                  onDragEnter={handleDragEnter}
                  onDragOver={(e) => e.preventDefault()}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  {image ? (
                    <img
                      src={image}
                      alt="Uploaded"
                      className="h-full w-full object-contain rounded-lg"
                    />
                  ) : (
                    <label
                      htmlFor="upload"
                      className={`flex flex-col items-center justify-center space-y-2 ${
                        isDragging ? "border-blue-500" : "border-gray-400"
                      }`}
                    >
                      <img src="/images/featured-icon.png" alt="upload img" />
                      <span className="text-lg text-[#FCCF08] font-bold cursor-pointer">
                        Click to upload{" "}
                        <span className="text-[#1F1746] font-normal">
                          or drag and drop
                        </span>
                      </span>
                      <p className="text-sm text-[#626435]">
                        SVG, PNG, JPG or GIF (max. 800x400px)
                      </p>
                    </label>
                  )}
                  <input
                    type="file"
                    id="upload"
                    accept="image/*"
                    className="hidden"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full flex justify-center mt-4">
                  <button
                    disabled={isFileUploading}
                    onClick={onSubmit}
                    className="w-96 bg-btn-gold hover:bg-blue-70 text-black font-bold py-2 px-8 rounded-[34px] focus:outline-none focus:shadow-outline shadow-btn-shadow"
                  >
                    {isFileUploading ? (
                      <span className="text-slate-600">Loading...</span>
                    ) : (
                      <span className="text-slate-600">{t('process')}</span>
                    )}
                  </button>
                </div>

                {/* {uploadError && (
                  <span className="w-96 bg-red-400 text-white p-2 rounded-md mt-3">
                    {responseMessage}
                  </span>
                )} */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NotLogin />
        </>
      )}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex flex-col items-center">
          {/* <SuccessAnimation /> */}
          <span className="my-4" ref={(_subtitle) => (subtitle = _subtitle)}>
            <h2 className="text-[#344054] font-bold max-w-md text-center">
              {responseMessage}
            </h2>
          </span>
          <div className="w-full flex justify-end mt-3">
            <button
              onClick={closeModal}
              className="bg-btn-gold rounded-lg text-sm w-16 h-7 hover:bg-gold2"
            >
              {t('close')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DepositUpload;
