function handleFilterArticle(articles, action) {
  return articles.filter((article) =>
    action === "View All" ? article.category : article.category === action
  );
}

function handlePagination(articles, startPage, itemsPerPage) {
  const endIndex = startPage * itemsPerPage;
  const startIndex = endIndex - itemsPerPage;

  if (endIndex < articles.length) {
    return articles.slice(startIndex, endIndex);
  } else {
    return articles.slice(startIndex, articles.length);
  }
}

function handleMaxPage(articles, itemsPerPage) {
  return Math.ceil(articles.length / itemsPerPage);
}

function handleSearchArticle(articles, keyword) {
  return articles.filter(
    (prop) =>
      prop.title.toLowerCase().includes(keyword.toLowerCase()) ||
      prop.body.toLowerCase().includes(keyword.toLowerCase())
  );
}

export {
  handleFilterArticle,
  handlePagination,
  handleMaxPage,
  handleSearchArticle,
};
