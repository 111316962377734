import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import imageEmailVerification from "../../assets/images/auth/email-verification.png";
import { emailVerification } from "../../features/auth/authSlice";
import Button from "../../components/utilities/button";
import SuccessAnimation from "../../components/animations/SuccessAnimation";

// modal custom
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function VerifyEmail(props) {
  const { t } = useTranslation();
  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  // console.log(user);
  // const [userEmail] = useState(user.email);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [resMsg, setResMsg] = useState("");
  const { email } = useParams();
  const [values, setValues] = useState([null, null, null, null, null]);

  const [messageVerificationCode, setMessageVerificationCode] =
    useState(message);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log(location.pathname);

  useEffect(() => {
    if (isError) {
      setMessageVerificationCode(message);
    }

    if (isSuccess === true || message === "Selamat, Anda berhasil mendaftar.") {
      navigate(`/auth-success/${email}`);
    }
  }, [isError, isSuccess, message, navigate, dispatch, email]);

  const handleChange = (index, value) => {
    const intValue = value;
    if (!isNaN(intValue) || intValue === 0) {
      const newValues = [...values];
      newValues[index] = intValue;
      setValues(newValues);

      if (index < 4 && value !== "") {
        const nextInput = document.getElementById(`input-${index + 1}`);
        nextInput && nextInput.focus();
      }
    }
  };

  const handlePaste = (index, event) => {
    event.preventDefault();
    const clipData = event.clipboardData.getData("text/plain").split("");

    let newValues = [...values];

    for (let i = index; i < values.length; i++) {
      if (clipData.length > 0) {
        const intValue = parseInt(clipData.shift());
        if (!isNaN(intValue)) {
          newValues[i] = intValue;
        } else {
          newValues[i] = null;
        }
      } else {
        newValues[i] = null;
      }
    }

    setValues(newValues);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const combineNumber = parseInt(values.join(""));
    const code = combineNumber.toString();
    console.log({ combineNumber });
    console.log(typeof combineNumber);
    console.log({ email });

    const codeVerification = {
      email: email,
      code: code.toString(),
    };

    console.table(codeVerification);

    dispatch(emailVerification(codeVerification));

    setMessageVerificationCode(message);

    console.log({ isSuccess });
  };

  // handle modal
  let subtitle;

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const requestEmailVerificationToken = async () => {
    const urlDev = process.env.REACT_APP_API_URL_DEV;
    const urlProd = process.env.REACT_APP_API_URL_PROD;

    const API_URL =
      process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

    try {
      const apiUrl = `${API_URL}/auth/email-verification-token-request`;

      const requestBody = {
        email: email,
      };

      const response = await axios.post(apiUrl, requestBody);
      console.log("Response:", response.data);

      setResMsg(response.data.msg);
      openModal();

      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const resetMessage = () => {
    setMessageVerificationCode("");
  };

  return (
    <>
      <div className="flex flex-col justify-center md:items-center md:w-4/6 xl:w-2/6 mx-auto h-screen">
        <div className="flex flex-col items-center px-8 mb-3">
          <img src={imageEmailVerification} alt="email verification" />
          <h1 className="font-medium text-xl md:text-2xl lg:text-4xl mb-2">
            {t('verify_email_title')}
          </h1>
          <p className="text-[#667085] text-center">
            {t('verify_email_desc_a')} <br /> {email}
          </p>
          {/* <p>{user.email}</p> */}
        </div>
        <form onSubmit={onSubmit} className="w-full px-8 pt-6 pb-8 mb-4">
          <div className="flex justify-center gap-2 mx-2 mb-4">
            {Array.from({ length: 5 }, (_, index) => (
              <input
                onClick={resetMessage}
                className="w-12 h-12 rounded-md border-[#D0D5DD] text-[#555555]"
                key={index}
                id={`input-${index}`}
                type="text"
                maxLength="1"
                value={values[index] || ""}
                onChange={(e) => handleChange(index, e.target.value)}
                onPaste={(e) => handlePaste(index, e)}
              />
            ))}
            {/* <input id="codeVerification" name="code" type="number" value={code} placeholder="00000" onChange={onChange} maxLength="5" className="appearance-none border-0 border-b-2 max-w-[200px] tracking-[1rem] text-2xl text-center py-2 px-3 text-gray-700 leading-tight focus:outline-none outline-0 focus:border-t-0 focus:border-r-0 focus:border-l-0 focus:ring-0" /> */}
          </div>
          {/* <div className="mb-4">{isError && <div className="mt-1 text-sm text-red-500">{messageVerificationCode}</div>}</div> */}
          <div className="w-full flex justify-center">
            <div className="w-[350px]">
              <Button width={"full"} type={"submit"}>
                {t('verify')}
              </Button>
              <p className="text-center text-[#667085] mt-3">
                {t('verify_email_desc_b')}?
                <span
                  onClick={requestEmailVerificationToken}
                  className="inline-block align-baseline text-[#1480E3] hover:text-blue-800 ml-1 cursor-pointer"
                  href="#"
                >
                  {t('resend')}
                </span>
              </p>
            </div>
          </div>
        </form>


        {messageVerificationCode && (
          <div className="w-[350px] p-3 rounded-md -mt-8 bg-red-400">
            <span className="text-sm text-white">
              {messageVerificationCode}
            </span>
          </div>
        )}

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="flex flex-col items-center">
            <SuccessAnimation />
            <span className="my-4" ref={(_subtitle) => (subtitle = _subtitle)}>
              <h2 className="text-[#344054] font-bold max-w-md text-center">
                {resMsg}
              </h2>
            </span>
            <div className="w-full flex justify-end mt-3">
              <button
                onClick={closeModal}
                className="bg-btn-gold rounded-lg text-sm w-16 h-7 hover:bg-gold2"
              >
                {t('close')}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default VerifyEmail;
