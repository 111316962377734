// jika ingin menambahkan artikel harap masukan juga kategori (jika kategori baru) pada 'Categories' sesuai kategori yang dimasukan 'pada blogArticles' dan juga warna nya

// generator unique ID untuk artikel
const uniqueId = () => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substr(2);
  return dateString + randomness;
};

// kategori ini berisikan value warna yang akan digunakan pada BlogHome, dan nama properti juga digunakan pada BlogNav
const categories = {
  Emas: "text-[#1d1d1e] bg-[#1d1d1e]/10",
  Token: "text-[#026aa2] bg-[#026aa2]/10",
  Aset: "text-[#027a48] bg-[#027a48]/10",
  Bisnis: "text-[#c01048] bg-[#c01048]/10",
  // Leadership: "rgba(192, 16, 72, 0.3)",
  // Management: "rgba(2, 106, 162, 0.3)",
};

// data untuk artikel
const blogArticles = [
  {
    id: uniqueId(),
    title: "Ini dia sederat aset kripto made in Indonesia",
    writer: "PT Xaurius Asset Digital (Xaurius)",
    date: "15 Februari 2024",
    category: "Emas",
    body: "Industri aset kian populer dan semakin berkembang di Indonesia. Tak heran jika pada akhirnya beberapa perusahaan cryptocurrency pun mencari peluang, salah satunya adalah dengan menerbitkan aset kripto.",
    img: "https://foto.kontan.co.id/ALe8jJAiD_Pqg3ebH5fruwxOIX0=/smart/2021/04/30/2038220251p.jpg",
    link: "https://www.kontan.co.id/tag/pt-xaurius-asset-digital-xaurius",
  },
  {
    id: uniqueId(),
    title: "Mengenal Crypto Emas dari Xaurius",
    writer: "Yani Petriella",
    date: "22 Mei 2021",
    category: "Emas",
    body: "Instrumen investasi saat ini tak hanya sebatas saham, deposito, ataupun reksadana, tetapi juga investasi kripto, karena mata uang kripto atau cryptocurrency telah menjadi salah satu alternatif investasi yang sangat digemari.",
    img: "https://images.bisnis.com/posts/2021/05/22/1396866/bitcoin.jpg",
    link: "https://market.bisnis.com/read/20210522/235/1396866/mengenal-crypto-emas-dari-xaurius",
  },
  {
    id: uniqueId(),
    title: "Mengenal Xaurius, Aset Kripto Bernilai Emas Pertama di Indonesia",
    writer: "Busrah Ardans",
    date: "18 Jan 2022",
    category: "Emas",
    body: "Aset kripto bernilai emas yang dimiliki Xaurius merupakan yang pertama di  Indonesia, dan dikenal sebagai kategori stablecoin.",
    img: "https://blockchainmedia.id/wp-content/uploads/2021/04/xaurius-indonesia.jpg",
    link: "https://blockchainmedia.id/mengenal-xaurius-aset-kripto-bernilai-emas-pertama-di-indonesia/",
  },
  {
    id: uniqueId(),
    title: "Token Xau Besutan Xaurius Didukung Emas",
    writer: "Edo Rusyanto",
    date: "20 Mei 2021",
    category: "Business",
    body: "Platform Xaurius dirancang khusus untuk mempermudah transaksi emas, yang proses jual belinya masih tradisional.",
    img: "https://img2.beritasatu.com/cache/investor/960x620-3/1621510302.jpg",
    link: "https://investor.id/business/248872/token-xau-besutan-xaurius-didukung-emas",
  },
  {
    id: uniqueId(),
    title: "Xaurius Rilis Token XAU Berbasis Emas Pertama di Indonesia",
    writer: "TechoBusiness Media",
    date: "24 Mei 2021",
    category: "Bisnis",
    body: "Xaurius Rilis Token XAU Berbasis Emas Pertama di Indonesia",
    img: "https://technobusiness.id/wp-content/uploads/2021/05/WhatsApp-Image-2021-05-17-at-19.42.27.jpeg",
    link: "https://technobusiness.id/news/2021/05/24/xaurius-rilis-token-xau-berbasis-emas-pertama-di-indonesia/",
  },
  {
    id: uniqueId(),
    title: "Xaurius Luncurkan Token Kripto Berbasis Emas",
    writer: "Aprilia Hariani",
    date: "20 Jan 2021",
    category: "Token",
    body: "Saat ini mata uang kripto (cryptocurrency) menjadi salah satu alternatif investasi di Indonesia",
    img: "https://www.pajak.com/storage/2021/05/thumbnail-73-758x569.jpg",
    link: "https://www.pajak.com/ekonomi/xaurius-luncurkan-token-kripto-berbasis-emas/",
  },
  {
    id: uniqueId(),
    title: "Xaurius Token Aset Kripto yang Stabil",
    writer: "Tempo",
    date: "19 Mei 2021",
    category: "Token",
    body: "Tak sedikit orang yang enggan berinvestasi di aset kripto, karena volatilitas harganya yang sangat tinggi.  Sebut saja misalnya Dogecoin (DOGE)",
    img: "https://statik.tempo.co/data/2021/05/19/id_1022122/1022122_720.jpg",
    link: "https://inforial.tempo.co/info/1005019/xaurius-token-aset-kripto-yang-stabil",
  },
];

export { categories, blogArticles };
