import axios from "axios";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

// get OTP
const createOTP = async (token) => {
  console.log(token);
  const config = {
    headers: {
      JWT: token,
    },
  };
  const response = await axios.post(API_URL + "otps/create", "", config);
  return response.data;
};

export default createOTP;
