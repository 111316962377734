import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getEvm } from "../../features/evm/evmSlice";
import createOTP from "../../features/otp/otpService";
import Cookies from "js-cookie";
import Modal from "react-modal";
import createWithdrawXAU from "../../features/withdraw/xau/withdrawXAUService";
import SuccessAnimation from "../animations/SuccessAnimation";
import PesanImg from "../../assets/icons/email-icon.png";

// modal custom
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function FormWithdrawXAU() {
  const token = Cookies.get("jwt");
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { evm } = useSelector((state) => state.evm);
  const { otp } = useSelector((state) => state);
  const { isError, isSuccess, message } = useSelector(
    (state) => state.withdrawXAU
  );

  const getInitialToken = evm.data && evm.data.find((evm) => evm);
  const [getToken, setGetToken] = useState(
    getInitialToken ? getInitialToken.chain_id : 97
  );

  // console.log(otp.message);

  const [formData, setFormData] = useState({
    chain_id: getToken,
    withdraw_to: "",
    otp: "",
    amount: "",
  });

  const { withdraw_to, amount } = formData;

  const [messageBankName, setMessageBankName] = useState();
  const [messageETHAddress, setMessageETHAddress] = useState();
  const [messageTotalAmount, setMessageTotalAmount] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [resOTP, setResOTP] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [resModal, setResModal] = useState("");
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      console.log(message);
    }

    dispatch(getEvm());
  }, [user, otp, isError, isSuccess, message, navigate, dispatch, formData]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    // cek pemilihan bank
    if (e.target.value !== "") {
      setMessageBankName("");
    }

    setGetToken(e.target.value);

    // cek jika user typing . di jumlah withdraw
    if (amount === "") {
      setMessageTotalAmount("");
    } else {
      setMessageTotalAmount("");
    }
  };

  const onSubmit = async () => {
    try {
      setLoader(true);
      const res = await createWithdrawXAU(formData, token);

      if (res.success) {
        setSuccessModal(true);
        setTimeout(() => {
          resetState();
          closeModal();
        }, 3000);
      } else {
        setSuccessModal(false);
      }
      setResModal(res.msg);
    } catch (errr) {
      setSuccessModal(false);
      setResModal(errr.response.data.msg);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const reqOTP = async (e) => {
    e.preventDefault();
    // cek jika user typing . di jumlah withdraw
    if (withdraw_to === "" && amount === "") {
      // setMessageBankName("Please select bank name");
      setMessageETHAddress("Input your ETH Address");
      setMessageTotalAmount("Fill the amount");
    } else if (withdraw_to === "") {
      // setMessageBankName("Bank name couldn't be empty");
    } else if (amount === "") {
      setMessageTotalAmount("Fill the amount");
    } else {
      setMessageBankName("");
      setMessageETHAddress("");
      setMessageTotalAmount("");
      const res = await createOTP(token);
      setResOTP(res.msg);

      openModal();
    }
  };

  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];

  const handleChange = (index, e) => {
    const input = e.target;
    const value = input.value;

    if (value.length >= 1) {
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    } else {
      // Jika nilai di input saat ini kosong, pindahkan fokus ke input sebelumnya
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }

    const otpValue = inputRefs.map((ref) => ref.current.value).join("");
    setFormData({ ...formData, otp: otpValue });
  };

  // handle modal
  let subtitle;

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    // window.location.reload();
  }

  const resetState = () => {
    setFormData({
      chain_id: getToken,
      withdraw_to: "",
      otp: "",
      amount: "",
    });

    setMessageBankName("");
    setMessageETHAddress("");
    setMessageTotalAmount("");
    setResOTP("");
    setSuccessModal(false);
    setResModal("");

    if (inputRefs.every((ref) => ref.current)) {
      inputRefs.forEach((ref) => {
        ref.current.value = "";
      });

      if (inputRefs.length > 0) {
        inputRefs[inputRefs.length - 1].current.blur();
      }
    }
  };

  return (
    <>
      <div className="rounded-xl shadow-lg p-4 mb-12">
        <h1 className="pb-4 mb-8 border-b border-b-gray-200">Xaurius Token</h1>
        <form onSubmit={reqOTP}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="chain_id"
            >
              {t('network')}
            </label>
            <select
              id="withdrawXAUSelectToken"
              name="chain_id"
              value={getToken}
              onChange={onChange}
              className="block w-full mt-1 p-2 rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              {evm.data && evm.data ? (
                evm.data.map((token) => {
                  return (
                    <option key={token.id} value={token.chain_id}>
                      {token.network_name}
                      {/* {token.chain_id} */}
                    </option>
                  );
                })
              ) : (
                <option defaultValue>{t('select_bank')}</option>
              )}
            </select>
            {messageBankName && (
              <div className="mt-1 text-sm text-red-500">{messageBankName}</div>
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="withdraw_to"
            >
              {t('address_wallet')}
            </label>
            <input
              id="withdrawXAUETHAddress"
              name="withdraw_to"
              type="text"
              placeholder="0xEvmAddress"
              value={withdraw_to}
              onChange={onChange}
              className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {messageETHAddress && (
              <div className="mt-1 text-sm text-red-500">
                {messageETHAddress}
              </div>
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="amount"
            >
              <div className="flex justify-between">
                <span>{t('you_will_send')}</span>
                {/* <span className="text-sm font-normal">
                  Token tersedia: 8.00 XAU
                </span> */}
              </div>
            </label>
            <input
              id="withdrawXAUTotal"
              name="amount"
              type="text"
              value={amount}
              placeholder="0"
              onChange={onChange}
              className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {messageTotalAmount && (
              <div className="mt-1 text-sm text-red-500">
                {messageTotalAmount}
              </div>
            )}
          </div>
          <div className="flex flex-col items-center justify-between">
            <button
              className="w-full bg-btn-gold shadow-btn-shadow hover:bg-blue-70 text-[#344054] font-bold py-2 px-4 mb-3 rounded-[34px] focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {t('withdraw')}
            </button>
          </div>
        </form>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {successModal ? (
          <div className="flex flex-col items-center">
            <SuccessAnimation />
            <span>Data berhasil diambil</span>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <img src={PesanImg} alt="pesan icon" />
            <h2 className="font-bold text-lg">Kode OTP</h2>
            <span className="my-4" ref={(_subtitle) => (subtitle = _subtitle)}>
              <h2 className="text-[#344054] font-medium max-w-md text-center">
                {resOTP}
              </h2>
            </span>
            <div className="space-x-3 mt-3">
              {inputRefs.map((inputRef, index) => (
                <input
                  key={index}
                  ref={inputRef}
                  type="text"
                  maxLength={1}
                  size={1}
                  onChange={(e) => handleChange(index, e)}
                  onKeyDown={(e) => {
                    const charCode = e.key;
                    if (isNaN(parseInt(charCode)) && charCode !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                  className="w-[50px] h-[50px] rounded-md border border-[#D0D5DD] shadow-sm"
                />
              ))}
            </div>
            {!successModal && resModal && (
              <span className="text-sm text-pink-700 mt-3">{resModal}</span>
            )}

            {loader ? (
              <div className="w-full flex justify-end gap-3 mt-6">
                <button
                  onClick={closeModal}
                  className="bg-white shadow-btn-shadow rounded-lg text-sm w-16 h-7 hover:bg-gold2"
                >
                  <div
                    class="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      Loading...
                    </span>
                  </div>
                </button>
              </div>
            ) : (
              <div className="w-full flex justify-end gap-3 mt-6">
                <button
                  onClick={closeModal}
                  className="bg-white shadow-btn-shadow rounded-lg text-sm w-16 h-7 hover:bg-gold2"
                >
                  Batal
                </button>
                <button
                  onClick={onSubmit}
                  className="bg-btn-gold shadow-btn-shadow rounded-lg text-sm w-16 h-7 hover:bg-gold2"
                >
                  Kirim
                </button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
}

export default FormWithdrawXAU;
