// lastPriceSlice.js

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const urlDev = process.env.REACT_APP_API_URL_DEV;
const urlProd = process.env.REACT_APP_API_URL_PROD;

const API_URL = process.env.REACT_APP_ENV === "development" ? `${urlDev}` : `${urlProd}`;

export const lastPriceSlice = createSlice({
  name: "lastPrice",
  initialState: {
    priceData: {
      price: "",
      buy: "",
      sell: "",
    },
    loading: false,
    error: null,
  },
  reducers: {
    fetchPriceStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchPriceSuccess: (state, action) => {
      state.priceData = action.payload;
      state.loading = false;
    },
    fetchPriceFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Actions
export const { fetchPriceStart, fetchPriceSuccess, fetchPriceFailure } = lastPriceSlice.actions;

// Async action to fetch data from the API
export const fetchPriceData = () => async (dispatch) => {
  dispatch(fetchPriceStart());
  try {
    const response = await axios.get(`${API_URL}charts/last-price`);
    dispatch(fetchPriceSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchPriceFailure(error.message));
  }
};

// Reducer
export default lastPriceSlice.reducer;
