import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Button from "../utilities/button";
import imageIphone from "../../assets/images/iphone.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Hero() {
  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1 });
  const { t } = useTranslation();

  const leftToRight = {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 1 },
    },
  };

  const rightToLeft = {
    hidden: {
      x: 100,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 1 },
    },
  };

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    } else {
      animation.start("hidden");
    }
  }, [animation, inView]);

  return (
    <>
      <div className="h-full lg:h-[512px]">
        <div className="lg:flex px-4 md:px-20">
          <div className="basis-2/4">
            <div className="h-full px-8 py-8 md:py-24">
              <h1 className="text-4xl md:text-7xl font-semibold mb-4">{t('hero_title')}</h1>
              <p className="md:text-2xl">{t('hero_desc')}</p>
              <div className="mt-16">
                <Link className="bg-btn-gold text-[#344054] shadow-btn-shadow rounded-full px-8 py-2 font-bold" to={"/buy-xau"} >{t('buy_now')}</Link>
              </div>
            </div>
          </div>
          <div className="basis-2/4">
            <motion.div className="h-full py-8" ref={ref} animate={animation} variants={leftToRight}>
              <img src={imageIphone} alt="iPhone" />
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
