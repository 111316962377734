import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiChevronLeft } from "react-icons/hi2";

export const SuccessSellXAU = ({ state, status }) => {
  // console.log(state, status);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col items-center p-4 mb-4">
        <h1 className="text-xl font-semibold ml-4">{t('success_sell_xau_title')}</h1>
        <span>{t('success_sell_desc')} {state ? state.formData.amount_xau : null} XAU.</span>
        <Link to={"/history"}>
          <span className="font-semibold">{t('success_sell_link')}</span>
        </Link>
      </div>
    </>
  );
};
