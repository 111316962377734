import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { HiChevronDown } from "react-icons/hi2";
import idIcon from "../../../assets/icons/country/id.svg";
import usIcon from "../../../assets/icons/country/us.svg";

// modal custom
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function ModalAddBank() {
  const { t, i18n } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const currentLanguage = i18n.language;
  const [languageSelect, setLanguageSelect] = useState(currentLanguage);
  const changeLanguage = (lang) => {
    localStorage.setItem("language", lang);
  };

  // handle modal
  let subtitle;

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    window.location.reload();
  }

  function languageSelectHandler(language) {
    setLanguageSelect(language);
    toggleDropdown();
  }

  function toggleDropdown() {
    setIsDropdownOpen(!isDropdownOpen);
  }

  function onSubmitHandler() {
    changeLanguage(languageSelect);
    window.location.reload();
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="w-full min-w-[250px] lg:w-[350px]">
          <div className="w-full flex flex-col items-center">
            <div className="w-full flex justify-between items-center">
              <span ref={(_subtitle) => (subtitle = _subtitle)}>
                <h2 className="text-[#344054] font-bold max-w-md text-center">
                  {t('language')}
                </h2>
              </span>

              <button
                onClick={closeModal}
                className="text-[#344054] text-sm w-7 h-7 hover:text-blue-500"
              >
                <svg
                  className="unf-icon"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="#2E3137"
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                >
                  <path d="M13.06 12l7.47-7.47a.75.75 0 10-1.06-1.06L12 10.94 4.53 3.47a.75.75 0 10-1.06 1.06L10.94 12l-7.47 7.47a.75.75 0 001.06 1.06L12 13.06l7.47 7.47a.75.75 0 001.06-1.06L13.06 12z"></path>
                </svg>
              </button>
            </div>

            <div className="relative w-full my-8">
              <button
                onClick={toggleDropdown}
                className="w-full bg-white border border-gray-400 rounded px-3 py-2 flex gap-3 items-center"
              >
                <div className="w-full flex justify-between">
                  <span className="text-sm">
                    {languageSelect === "en" ? "English" : "Indonesia"}
                  </span>
                  <span className="relative overflow-hidden w-5 h-5 ml-3 rounded-full border border-slate-800">
                    <img
                      src={languageSelect === "en" ? usIcon : idIcon}
                      alt=""
                      className="w-full h-full absolute object-cover"
                    />
                  </span>
                </div>

                <HiChevronDown />
              </button>

              {/* Dropdown list */}
              {isDropdownOpen && (
                <ul className="absolute w-full mt-2 bg-white border border-gray-400 rounded-md">
                  <li
                    onClick={() => languageSelectHandler("en")}
                    className="px-3 py-2 flex items-center cursor-pointer hover:bg-gold2"
                  >
                    <span className="text-sm">English</span>
                    <span className="relative overflow-hidden w-5 h-5 ml-3 rounded-full border border-slate-800">
                      <img
                        src={usIcon}
                        alt=""
                        className="w-full h-full absolute object-cover"
                      />
                    </span>
                  </li>
                  <li
                    onClick={() => languageSelectHandler("id")}
                    className="px-3 py-2 flex items-center cursor-pointer hover:bg-gold2"
                  >
                    <span className="text-sm">Indonesia</span>
                    <span className="relative overflow-hidden w-5 h-5 ml-3 rounded-full border border-slate-800">
                      <img
                        src={idIcon}
                        alt=""
                        className="w-full h-full absolute object-cover"
                      />
                    </span>
                  </li>
                </ul>
              )}
            </div>

            <div className="w-full flex justify-end mt-3">
              <button
                onClick={onSubmitHandler}
                type="button"
                className="bg-btn-gold rounded-lg text-sm font-bold w-full px-2 text-[#344054] h-10"
              >
                {t('save')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalAddBank;
