import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Review from "../../../assets/images/review.gif";

export const PendingKYC = ({ status }) => {
  const { t } = useTranslation();
  const { createKYC, message } = useSelector((state) => state.kyc);
  console.log(createKYC);

  const [messageKYC, setMessageKYC] = useState(message);

  return (
    <>
      <div className="w-full lg:w-[500px] flex flex-col items-center p-4 mb-4">
        <img src={Review} alt="" />
        <h1 className="text-xl text-center font-semibold ml-4">
          {t('pending_kyc_title')}
        </h1>
        <span className="text-center text-[#667085] my-3">{messageKYC}</span>
        <Link to={"/dashboard"}>
          <span className="font-semibold text-[#1480E3]">{t('pending_kyc_link')}</span>
        </Link>
      </div>
    </>
  );
};
