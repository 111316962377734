import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { createBuy, reset } from "../../features/buy/xau/buyXAUSlice";
import { fetchPriceData } from "../../features/price/lastPriceSlice";
import { getHistory } from "../../features/history/historySlice";
import formatToRupiah from "../helper/formatToRupiah";

import SuccessAnimation from "../animations/SuccessAnimation";
import WarningImg from "../../assets/images/warning-icon.png";
import balanceIDRService from "../../features/balance/idr/balanceIDRService";
import Cookies from "js-cookie";

// modal custom
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function FormBuyXAU() {
  const { t } = useTranslation();
  const { user, buyXAU, isError, isSuccess, message } = useSelector(
    (state) => state.buyXAU
  );
  const priceData = useSelector((state) => state.lastPrice.priceData);
  const [balanceData, setBalanceData] = useState(null);

  const [resMsg, setResMsg] = useState("");
  const [resIsSuccess, setResIsuccess] = useState(false);

  const [formData, setFormData] = useState(0);
  const [estimatedPrice, setEstimatedPrice] = useState(0);

  const [messagePrice, setMessagePrice] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  // const [messageTotalAmount, setMessageTotalAmount] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = Cookies.get("jwt");

  useEffect(() => {
    if (isError) {
      setResIsuccess(false);
      setResMsg(message);
    }

    if (isSuccess) {
      dispatch(reset());
      setResIsuccess(true);
      setResMsg(message);
      dispatch(getHistory());
    }

    dispatch(reset());
    dispatch(fetchPriceData());

    const fetchBalanceData = async () => {
      try {
        const data = await balanceIDRService.getBalance(token);
        const filterData = data.data.filter((item) => item.currency === "IDR");
        setBalanceData(filterData);
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    };

    fetchBalanceData();
  }, [user, buyXAU, isError, isSuccess, message, navigate, dispatch, token]);

  const onChange = (e) => {
    const input = e.target.value;

    // Hanya izinkan input berupa angka dan koma (desimal)
    const onlyNums = input.replace(/[^0-9.,]/g, "");

    setFormData(onlyNums);
    calculateEstimate(input);
    if (input !== 0) {
      setMessagePrice("");
    }
  };

  const calculateEstimate = (val) => {
    if (val) {
      const pricePerAux = parseFloat(priceData.buy);
      const idr = parseFloat(val);
      console.log(`Hitung: IDR / Harga Beli ${idr / pricePerAux}`);
      const estimated = idr / pricePerAux;
      setEstimatedPrice(estimated);
    } else {
      setEstimatedPrice(0);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const datas = {
      amount_idr: formData,
    };

    // cek jika user typing . di jumlah deposit
    if (formData === "" || formData === 0) {
      setMessagePrice("Nilai tidak boleh kosong");
    } else if (isError) {
      setMessagePrice(message);
    } else {
      dispatch(createBuy(datas));
      setMessagePrice("");
      openModal();
    }
  };

  // handle modal
  let subtitle;

  function openModal() {
    setIsOpen(true);
    setTimeout(() => {
      closeModal();
    }, 3000);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    setFormData(0);
    setEstimatedPrice(0);
  }

  return (
    <>
      <div className="rounded-xl shadow-lg p-4 mb-12 h-full">
        <h1 className="pb-4 mb-8 border-b border-b-gray-200">{t('buy_xau')}</h1>
        <div className="flex justify-between mb-8">
          <span className="text-gray-500">{t('available_balance')}</span>
          <span className="text-gray-500">
            {balanceData ? formatToRupiah(balanceData[0].balance_value) : null}
          </span>
        </div>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="price"
            >
              {t('want_buy')}
            </label>
            <div className="relative">
              <span className="absolute right-2 top-2 px-2 py-0 bg-[#FEF0C7] text-[#DC6803] rounded-md text-sm">
                IDR
              </span>
              <input
                id="buyXAUAmount"
                name="price"
                type="text"
                value={formData}
                placeholder="0"
                onChange={onChange}
                className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            {messagePrice && (
              <div className="mt-1 text-sm text-red-500">{messagePrice}</div>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t('estimate')}
            </label>
            <span className="text-[#667085] text-sm">
              {parseFloat(estimatedPrice).toFixed(2)}{" "}
              <span className="text-[#DC6803]">XAU</span>
            </span>
          </div>
          <div className="flex flex-col items-center justify-between">
            <button
              className="w-full bg-btn-gold shadow-btn-shadow hover:bg-blue-70 text-[#344054] font-bold py-2 px-4 mb-3 rounded-[34px] focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {t('buy')}
            </button>
          </div>
        </form>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="w-full lg:w-[300px] flex flex-col items-center">
          {resIsSuccess ? (
            <SuccessAnimation />
          ) : (
            <img src={WarningImg} alt="warning" width="150" />
          )}
          <span
            className="mt-4 mb-1"
            ref={(_subtitle) => (subtitle = _subtitle)}
          >
            <h2 className="text-[#344054] font-bold max-w-md text-center">
              {resMsg}
            </h2>
          </span>
        </div>
      </Modal>
    </>
  );
}

export default FormBuyXAU;
