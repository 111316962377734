import React from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function BreadcrumbBack({ title }) {
  const { t } = useTranslation();
    const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This navigates back one step in the history stack
  };

  const renamedTitle = (title) =>
    title &&
    title
      .split(" ")
      .map((word) => word[0].toLowerCase() + word.slice(1))
      .join("_");

  return (
    <div onClick={handleGoBack} className="flex items-center gap-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6485 20.0485C11.1799 20.5171 10.4201 20.5171 9.95147 20.0485L2.75147 12.8485C2.28284 12.3799 2.28284 11.6201 2.75147 11.1514L9.95147 3.95145C10.4201 3.48282 11.1799 3.48282 11.6485 3.95145C12.1172 4.42008 12.1172 5.17988 11.6485 5.6485L6.49705 10.8H20.4C21.0627 10.8 21.6 11.3372 21.6 12C21.6 12.6627 21.0627 13.2 20.4 13.2L6.49705 13.2L11.6485 18.3514C12.1172 18.8201 12.1172 19.5799 11.6485 20.0485Z"
          fill="#111827"
        />
      </svg>
      <h2 className="font-bold">{t(renamedTitle(title))}</h2>
    </div>
  );
}

export default BreadcrumbBack;
