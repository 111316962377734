// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes successAnimation {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .animate-success {
    animation: successAnimation 0.5s ease-out;
  }
`, "",{"version":3,"sources":["webpack://./src/components/animations/SuccessAnimation.css"],"names":[],"mappings":"AAAA;IACI;MACE,mBAAmB;MACnB,UAAU;IACZ;IACA;MACE,mBAAmB;MACnB,UAAU;IACZ;EACF;;EAEA;IACE,yCAAyC;EAC3C","sourcesContent":["@keyframes successAnimation {\r\n    0% {\r\n      transform: scale(0);\r\n      opacity: 0;\r\n    }\r\n    100% {\r\n      transform: scale(1);\r\n      opacity: 1;\r\n    }\r\n  }\r\n\r\n  .animate-success {\r\n    animation: successAnimation 0.5s ease-out;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
