import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import copy from "clipboard-copy";
import { useTranslation } from "react-i18next";
import {
  fetchNetworks,
  selectAllNetworks,
  selectNetworksStatus,
} from "../../features/networks/networksSlice";
import { resetState } from "../../features/address/addressSlice";
import axios from "axios";
import Cookies from "js-cookie";
import Copy from "../../assets/icons/copy.png";
import Copied from "../../assets/icons/copied.png";

function FormDepositXAU() {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { isError, isSuccess, message } = useSelector(
    (state) => state.depositIDR
  );

  const [address, setAddress] = useState("");
  const [hoverSelected, setHoverSelected] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const networks = useSelector(selectAllNetworks);
  const status = useSelector(selectNetworksStatus);

  // const walletAddress = useSelector(selectWalletAddress);
  // const statusAddress = useSelector(selectAddressStatus);
  // const addressMsg = useSelector(selectErr);

  const urlDev = process.env.REACT_APP_API_URL_DEV;
  const urlProd = process.env.REACT_APP_API_URL_PROD;
  const API_URL =
    process.env.REACT_APP_ENV === "development" ? urlDev : urlProd;

  const token = Cookies.get("jwt");
  const config = {
    headers: {
      JWT: token,
    },
  };

  const requestAddress = async () => {
    // dispatch(fetchAddress());
    const res = await axios.get(`${API_URL}profiles/evm-address`, config);
    if (res.data.success) {
      setAddress(res.data.data);
    }
  };

  useEffect(() => {
    const cekAddress = async () => {
      const res = await axios.get(
        `${API_URL}ccdeposits/request-address`,
        config
      );

      if (res.data.success === false) {
        if (res.data.msg === "Sudah punya address") {
          requestAddress();
        } else {
          console.log(res.data.msg);
        }
      } else {
        setAddress(res.data.data.wallet_address);
      }
    };

    cekAddress();
  }, []);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchNetworks());
    }

    dispatch(resetState());
    // cekAddress();
  }, [user, isError, isSuccess, message, navigate, dispatch, status]);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Error fetching data.</div>;
  }

  const handleMouseEnter = (val) => {
    setHoverSelected(val);
  };

  const handleMouseLeave = () => {
    setHoverSelected(null);
    setIsClicked(false);
  };

  const handleCopyClick = (val) => {
    setIsClicked(true);
    copy(val);
  };

  return (
    <>
      <div className="rounded-xl shadow-lg p-4 mb-12">
        <div className="w-full flex flex-col items-center">
          <div className="flex justify-center ">
            <div className="w-full">
              {!address && (
                <button
                  onClick={requestAddress}
                  className="w-full bg-yellow-color py-2 rounded-lg text-sm mb-4"
                >
                  {t('request_address')}
                </button>
              )}

              {/* qr-code */}
              <div
                className={`grid grid-cols-1 md:grid-cols-2 space-x-4 mt-3 blur-md ${
                  address && "blur-none"
                }`}
              >
                <div className="max-w-2xl">
                  <h2 className="text-base font-semibold mb-3">
                  {t('form_deposit_xau_title_1')}
                  </h2>
                  <p className="text-sm text-[#2B2C24]">
                  {t('form_deposit_xau_desc_1')}
                  </p>
                  <input
                    type="text"
                    defaultValue={address}
                    className="w-full mt-3 bg-[#E8E8E8] border-none text-[#2B2C24]"
                  />
                </div>
                <QRCode value={address} />
              </div>
            </div>
          </div>

          <div className="w-full mt-14">
            <h2 className="text-base font-medium">{t('form_deposit_xau_title_2')}</h2>
            <p className="text-sm">{t('form_deposit_xau_desc_2')}:</p>
          </div>
          <div className="w-full relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full mt-4 text-sm text-left text-gray-900 bg-[#E8E8E8]">
              <thead className="text-xs text-gray-900 bg-[#C1C1C1]">
                <tr>
                  <th scope="col" className="px-6 py-3">
                  {t('chain_id')}
                  </th>
                  <th scope="col" className="px-6 py-3">
                  {t('chain_name')}
                  </th>
                  <th scope="col" className="px-6 py-3">
                  {t('address')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {networks.map((network) => {
                  const { id, chain_id, network_name, contract_address } =
                    network;
                  const truncatedAddress =
                    contract_address.length > 10
                      ? `${contract_address.substring(
                          0,
                          5
                        )}...${contract_address.substring(
                          contract_address.length - 5
                        )}`
                      : contract_address;

                  return (
                    <tr
                      key={id}
                      className="bg-[#E8E8E8] border-b border-slate-300"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-[#667085] whitespace-nowrap"
                      >
                        {chain_id}
                      </th>
                      <td className="px-6 py-4">{network_name}</td>
                      <td
                        onClick={() => handleCopyClick(contract_address)}
                        onMouseOver={() => handleMouseEnter(id)}
                        onMouseLeave={() => handleMouseLeave(id)}
                        className="px-6 py-4 relative"
                      >
                        {truncatedAddress}
                        {hoverSelected === id && (
                          <img
                            src={isClicked ? Copied : Copy}
                            alt="copy"
                            width="80"
                            className="absolute -top-3"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default FormDepositXAU;
